import React, {FC, useState, useEffect} from 'react'
import {useLocation, useHistory, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {DefauktModel} from '../../../../../_metronic/partials/widgets'
import DataGridTable from '../../../../comomn/DataGridTable'
import {companies} from '../../../../redux/actions/companyAction'
import {RootState} from '../../../../redux/reducers'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {users} from '../../../../redux/actions/authAction'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const EditBranch: FC = () => {
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const barnchPosition: any = useSelector<RootState>(({companies}) => companies.barnchPosition)
  const branch: any = useSelector<RootState>(({companies}) => companies.branchEdit)

  const [branchFilter, setBranchFilter] = useState<string>('')

  const history = useHistory()
  const params: any = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    if (params.branch_uid) {
      dispatch(companies.editListBranch(params.id, params.branch_uid))
      dispatch(companies.branchPositions(params.branch_uid))
    }
    if (success.success === true || success.success === false) {
      successMsg(success)
    }
  }, [success.success, params.branch_uid])

  const successMsg = (msg: any) => {
    if (msg.success === true) {
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm',
          cancelButton: 'btn btn-white',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // dispatch(companies.positionsList(params.id))
          dispatch(companies.clearPopup())
        }
      })
    } else if (msg.success === false) {
      MySwal.fire({
        html: `${msg.error && msg.error.errorInfo ? msg.error.errorInfo.message : msg.error}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // setPopup(true)
          // closeModalRef.current.click()
          // setLoading(false)
        }
      })
    }
  }

  const columns: any = [
    {field: 'name_en', header: 'Positions'},
    {field: 'action', header: 'DETAILS'},
  ]

  const modifyColumns = (rowData: any, col: any) => {
    switch (col.field) {
      case 'action':
        return editButton(rowData)

      default:
        return <span className='text-dark fw-bolder mb-1 fs-6'>{rowData[col.field]}</span>
    }
  }

  const editButton = (row: any) => {
    // MenuComponent.reinitialization()
    return (
      <div style={{width: '100%', textAlign: 'right'}}>
        <span
          className='btn btn-light btn-active-light-primary btn-sm p-3'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => MenuComponent.reinitialization()}
        >
          Edit
        </span>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <span className='menu-link px-3 link-primary' onClick={() => editPosition(row)}>
              Edit
            </span>
          </div>

          <div className='menu-item px-3'>
            <span className='menu-link px-3 text-danger' onClick={() => deletePositionFn(row)}>
              Delete
            </span>
          </div>
        </div>
      </div>
    )
  }

  const deletePositionFn = (row: any) => {
    MySwal.fire({
      html: `Are you sure you want to delete ${row.name_en} ?`,
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      padding: '2.25rem',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-danger btn-sm me-2',
        cancelButton: 'btn btn-white',
        icon: 'text-warning border-warning mb-5',
        container: 'py-7',
      },
      showClass: {
        popup: 'swal2-show',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show',
      },
      hideClass: {
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeletPosition(row)
      }
    })
  }

  const confirmDeletPosition = (row: any) => {
    dispatch(companies.delatePositions(row.id))
  }

  const branchPositionFilterFn = () => {
    const filterModel: any = {}
    filterModel.name_en = {value: branchFilter}
    return filterModel
  }

  const branchPositionSearchFn = (e: any) => {
    setBranchFilter(e.target.value)
  }

  const editPosition = (row: any) => {
    let redirectPath: string = `/companies/companyProfile/${params.id}/editPosition/${row.id}`
    history.push(redirectPath)
    dispatch(companies.branchPositioncheck(params.branch_uid))
    dispatch(users.positionBranch(false))
  }
  const addPosition = () => {
    let redirectPath: string = `/companies/companyProfile/${params.id}/createPosition`
    history.push(redirectPath)
    dispatch(companies.branchPositioncheck(params.branch_uid))
    dispatch(users.positionBranch(false))
    dispatch(users.branchPositionCreate(true))
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {/* <div className='card mb-5 mb-xl-10'> */}
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-0'>
                <div className='d-flex flex-column pt-13'>
                  <div className='d-flex align-items-center mb-10'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bolder'>
                      Quraysh, Mcdonald's
                      {/* {branch.length > 0 && branch[0].company_name ? branch[0].company_name : ''} */}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-5'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center mb-1'>
                        <span className='svg-icon svg-icon-danger svg-icon-1px me-5'>
                          <KTSVG
                            path='/as3a-media/DuoTune_Pro/svg/map009.svg'
                            className='svg-icon-1'
                          />
                        </span>
                        {branch && branch.city && branch.city.name_en ? (
                          <div className='fs-4 fw-bolder'>{branch.city.name_en}</div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='fw-bold fs-4 text-gray-400'>City</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center mb-1'>
                        <span className='svg-icon svg-icon-info svg-icon-1px me-5'>
                          <KTSVG
                            path='/as3a-media/DuoTune_Pro/svg/teh002.svg'
                            className='svg-icon-1'
                          />
                        </span>
                        {branch && branch.manager && branch.manager.name ? (
                          <div className='fs-2 fw-bolder'>{branch.manager.name}</div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className='fw-bold fs-4 text-gray-400'>Manager</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center mb-1'>
                        <span className='svg-icon svg-icon-1px svg-icon-warning me-5'>
                          <KTSVG
                            path='/as3a-media/DuoTune_Pro/svg/gen019.svg'
                            className='svg-icon-1'
                          />
                        </span>

                        <div className='fs-2 fw-bolder'>
                          {/* // <div className='fs-2 fw-bolder'>{branch[0].manager.name}</div> */}
                          {branch && branch.status === 'active' ? (
                            <span className='badge badge-light-success fs-7 fw-bolder'>
                              {branch.status}
                            </span>
                          ) : branch && branch.status === 'inactive' ? (
                            <span className='badge badge-light-danger fs-7 fw-bolder'>
                              {branch.status}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className='fw-bold fs-4 text-gray-400'>Status</div>
                    </div>
                  </div>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder'>
                      <li className='nav-item'>
                        <a
                          className='nav-link active text-active-primary'
                          data-bs-toggle='tab'
                          href='#kt_tab_pane_1'
                        >
                          Corresponding Positions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='d-flex'>
                  <img
                    alt=''
                    src={toAbsoluteUrl('/as3a-media/sketchy_pro_svg_v1.0/present.svg')}
                    style={{width: '220px', height: '220px'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <h1 className='d-flex align-items-center text-dark-300 fw-bolder fs-3'>
        Corresponding Positions
      </h1>
      <div className='card card-xxl-stretch mb-5 mb-xxl-8 pt-5'>
        <div className='card-body py-3'>
          <div className='d-flex flex-stack mb-5'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='black'
                  ></rect>
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='black'
                  ></path>
                </svg>
              </span>
              <input
                type='text'
                data-kt-docs-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search Position'
                onChange={(e) => branchPositionSearchFn(e)}
              />
            </div>

            <div className='d-flex justify-content-end' data-kt-docs-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-sm btn-bg-primary btn-color-white fw-bolder'
                onClick={() => addPosition()}
              >
                <span className='svg-icon svg-icon-white me-1 '>
                  <KTSVG path='/as3a-media/DuoTune_Pro/svg/arr009.svg' className='w-30px h-30px' />
                </span>
                Add Position
              </button>
            </div>
          </div>
          <div className='table-responsive'>
            <DataGridTable
              columns={columns}
              rows={barnchPosition}
              filterModel={branchPositionFilterFn()}
              modifyColums={(rows, col) => modifyColumns(rows, col)}
            />
          </div>
        </div>
        {/* <CreateBranch /> */}
      </div>
    </>
  )
}

export default EditBranch
