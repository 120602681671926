import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import {RootState} from '../../redux/reducers'
import {users} from '../../redux/actions/authAction'

const Authinit: FC = (props) => {
  const user: any = useSelector<RootState>(({auth}) => auth.users, shallowEqual)
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(false)

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default Authinit
