/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {RootState} from '../../redux/reducers'

import {
  NumberOfHours,
  NumberOfShifts,
  NumberOfCompanies,
  Ambitious,
  Attendance,
  Fullfillment,
} from './components'

const Dashboard: FC = () => {
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {permissions.length > 0 &&
        permissions[0]['permissions']['dashboard'].view_growth === true ? (
          <>
            <h1 className='d-flex align-items-center text-dark-300 fw-bolder fs-1'>Growth</h1>
            <div className='col-lg-12'>
              <div className='row fv-row'>
                <div className='col-sm-12'>
                  <NumberOfHours
                    className='card-xxl-stretch-50 mb-5 mb-xl-8'
                    chartColor='#2596be'
                    chartHeight='390px'
                  />
                </div>
                {/* <div className='row fv-row'> */}
                <div className='col-sm-3'>
                  <div
                    className='d-flex align-items-center  rounded p-5 mb-7'
                    style={{backgroundColor: '#E6F3F8'}}
                  >
                    <span className='svg-icon svg-icon-3x svg-icon-primary me-9'>
                      <KTSVG path='/as3a-media/DuoTune_Pro/svg/gen013.svg' className='svg-icon-1' />
                    </span>

                    <div className='flex-grow-1 me-2'>
                      <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-1'>
                        4200
                      </a>
                      <span className='text-muted fw-bold d-block fs-6'>Hours</span>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
                    <span className='svg-icon svg-icon-success svg-icon-3x me-9'>
                      <KTSVG
                        path='/as3a-media/DuoTune_Pro/svg/arr026_1.svg'
                        className='svg-icon-1'
                      />
                    </span>

                    <div className='flex-grow-1 me-2'>
                      <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-1'>
                        64,501
                      </a>
                      <span className='text-muted fw-bold d-block fs-6'>Shifts</span>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div
                    className='d-flex align-items-center  rounded p-5 mb-7'
                    style={{backgroundColor: '#F0EFFF'}}
                  >
                    {/* <span className='svg-icon svg-icon-dark me-9'>
                    <KTSVG path='/as3a-media/DuoTune_Pro/svg/ecm008.svg' className='svg-icon-1' />
                  </span> */}
                    <span className='svg-icon svg-icon-3x svg-icon-info me-9'>
                      <KTSVG path='/as3a-media/DuoTune_Pro/svg/ecm008.svg' className='svg-icon-1' />
                    </span>

                    <div className='flex-grow-1 me-2'>
                      <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-1'>
                        100
                      </a>
                      <span className='text-muted fw-bold d-block fs-6'>Companies</span>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
                    <span className='svg-icon svg-icon-2x svg-icon-3x svg-icon-danger me-9'>
                      <KTSVG path='/as3a-media/DuoTune_Pro/svg/map009.svg' className='svg-icon-1' />
                    </span>

                    <div className='flex-grow-1 me-2'>
                      <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-1'>
                        100
                      </a>
                      <span className='text-muted fw-bold d-block fs-6'>Cities</span>
                    </div>
                  </div>
                </div>
                {/* </div> */}

                <div className='col-sm-6'>
                  <NumberOfShifts
                    className='card-xxl-stretch-50 mb-5 mb-xl-8'
                    chartColor='#4AB58E'
                    chartHeight='390px'
                  />
                </div>
                <div className='col-sm-6'>
                  <NumberOfCompanies
                    className='card-xxl-stretch-50 mb-5 mb-xl-8'
                    chartColor='#5F5CF1'
                    chartHeight='390px'
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div className='col-lg-12'>
          <h1 className='d-flex align-items-center text-dark-300 fw-bolder fs-1 mb-10'>General</h1>
          <div className='row fv-row'>
            <div className='col-sm-12'>
              <Ambitious
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='#2596be'
                chartHeight='410px'
              />
            </div>
            <div className='col-sm-6'>
              <Fullfillment
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='#E5E018'
                chartHeight='410px'
              />
            </div>
            <div className='col-sm-6'>
              <Attendance
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='dark'
                chartHeight='410px'
              />
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default Dashboard
