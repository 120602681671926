import React, {FC, useState, useEffect} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {useSelector} from 'react-redux'
import {RootState} from '../redux/reducers'

interface props {
  columns?: any
  rows?: any
  modifyColums?: (rows: any, col: any) => void
  filterModel?: any
}

const DataGridTable: FC<props> = ({columns, rows, modifyColums, filterModel}) => {
  const [filters, setFilters] = useState({})
  const apiLoader: any = useSelector<RootState>(({auth}) => auth.apiLoader)

  const dynamicColumns = columns.map((col: any, i: number) => {
    const headerFn = (header: any) => {
      switch (header) {
        case 'DETAILS':
          return <span style={{width: '100%', textAlign: 'right', display: 'block'}}>{header}</span>
        default:
          return header
      }
    }

    return (
      <Column
        key={col.field}
        field={col.field}
        header={headerFn(col.header)}
        // style={col.style}
        sortable={col.sortable}
        body={modifyColums ? (rows, col) => modifyColums(rows, col) : ''}
        className='text-start text-muted fw-bolder fs-7 gs-0'
        // sortable={col.field == 'Action' ? false : true}
        // sortable={true}
      />
    )
  })

  const paginatorLeft = <></>

  return (
    <div>
      <DataTable
        filters={filterModel}
        value={rows}
        loading={apiLoader}
        responsiveLayout='scroll'
        emptyMessage='No Records founds'
        rows={10}
        // rowsPerPageOptions={[10, 20, 50, 100]}
        paginator
        paginatorTemplate={
          'RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink'
        }
        currentPageReportTemplate=' Showing {first} to {last} of {totalRecords} entries'
        paginatorLeft={paginatorLeft}
        paginatorClassName='p-jc-end'
        className='p-datatable-sm'
        tableClassName='table align-middle
        table-row-dashed fs-6 gy-5 dataTable no-footer '
        columnResizeMode='fit'
        resizableColumns
        scrollable
      >
        {dynamicColumns}
      </DataTable>
    </div>
  )
}

export default React.memo(DataGridTable)
