const CreateBranchesValidations = {
    name_en: {
        required: {
            value: true,
            message: "name is required",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid branch name",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }

    },
    name_ar: {
        required: {
            value: true,
            message: "name is required",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid branch name",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }

    },
    location_description: {
        required: {
            value: true,
            message: "location description is required",
        },
    },

    city: {
        required: {
            value: true,
            message: "City name is required",
        },
    },

    manager: {
        name: {
            pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Please enter valid name",
            },
            minLength: {
                value: 2,
                message: "Please enter at least 2 characters",
            }

        },
        mobile: {
            pattern: {
                value: /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
                message: "Mobile number should start with 5",
            },
            // minLength: {
            //     value: 9,
            //     message: "Mobile number must contain 9 digits",
            // },
            maxLength: {
                value: 9,
                message: "Mobile number should not contain more than 9 digits",
            },

        },
    }

}
export default CreateBranchesValidations;



