import React from 'react'
import {MenuItem} from './MenuItem'
import {DefaultTitle} from './page-title/DefaultTitle'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      <DefaultTitle />
    </>
  )
}
