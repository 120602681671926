import { GET_ALL_USERS_LIST } from '../actions/types';

const initialState: any = {
    users: []
}

const usersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ALL_USERS_LIST:
            return { ...state, users: action.payload };
        default: return state;
    }

}

export default usersReducer;