import {
    API_SUCCESS,
    API_LOADER,
    PAGE_LOADER,
    SUB_PAGE_LOADER,
    GET_ALL_COMPANIES_LIST,
    CREATE_COMPANIES,
    UPDATE_COMPANIES,
    COMPANY_PROFILE_LIST,
    UPDATE_COMPANY_PROFILE,
    GET_BRANCHES_LIST,
    CREATE_BRANCH,
    EDIT_BRANCH_LIST,
    GET_CITI_LIST,
    UPDATE_BRANCH,
    BRANCHES_POSITION_LIST,
    BRANCH_POSITION_CHECK,
    POSITIONS_LIST,
    CREATE_POSITIONS,
    EDIT_POSITION_LIST,
    UPDATE_POSITIONS,

    GET_PHOTOS_LIST
} from './types';

import { collection, doc, getDoc, getDocs, setDoc, addDoc, query, where, updateDoc, orderBy, startAt, deleteDoc, Timestamp, collectionGroup } from "firebase/firestore";

import { getStorage, ref, uploadString, getDownloadURL, listAll, deleteObject } from "firebase/storage";

import { functions } from '../../config/fbConfig';
import { httpsCallable } from 'firebase/functions';

export const companies: any = {}

companies.companyList = () => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: PAGE_LOADER, payload: true })
    try {
        const colref = query(collection(getFirebase(), "companies"), orderBy("name_en"));
        const data: any = await getDocs(colref);
        const comp: any = data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: GET_ALL_COMPANIES_LIST, payload: comp })
        dispatch({ type: PAGE_LOADER, payload: false })
        // dispatch({ type: COMPANY_PROFILE_LIST, payload: {} })
        dispatch({ type: API_SUCCESS, payload: {} });
    } catch (err: any) {
        dispatch({ type: PAGE_LOADER, payload: false })
    }

};

companies.createCompany = (data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const companyObj = {
        name_en: data.name_en,
        name_ar: data.name_ar,
        company_name: data.name_en,
        cr_number: data.cr_number,
        vat_no: data.vat_no,
        employer_labor_office_id: data.employer_labor_office_id,
        employer_sequence_number: data.employer_sequence_number,
        active: true,
        company_logo_url: null,
        no_rating: 0,
        total_rating: 0
    }
    try {
        const colRef = collection(getFirebase(), "companies");
        await addDoc(colRef, companyObj)
        const success: any = { success: true, message: `Successfully created ${data.name_en}` };
        dispatch({ type: API_SUCCESS, payload: success })
        // dispatch({ type: CREATE_COMPANIES, payload: [] })
    } catch (err) {
    }

}


companies.companyProfileList = (id: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: PAGE_LOADER, payload: true })
    try {
        const colRef: any = doc(getFirebase(), "companies", id)
        const docSnap: any = await getDoc(colRef);
        const data: any = docSnap.data();
        dispatch({ type: COMPANY_PROFILE_LIST, payload: data })
        dispatch({ type: GET_ALL_COMPANIES_LIST, payload: [] })
        dispatch({ type: API_SUCCESS, payload: {} })
        dispatch({ type: PAGE_LOADER, payload: false })

    } catch (err: any) {
        dispatch({ type: PAGE_LOADER, payload: false })
    }
}

companies.companyProfileUpdate = (id: string, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const storage = getStorage();
    try {
        const colRef = doc(getFirebase(), "companies", id)
        if (data.company_logo_url && data.company_logo_url.name && data.active) {
            const metadata = {
                contentType: data.company_logo_url.type,
            };
            const storageRef: any = ref(storage, `/company_logo/${id}.jpg`);
            const snapshot: any = await uploadString(storageRef, data.company_logo_url.doc, "data_url")
            if (snapshot && snapshot.ref) {
                const url: any = await getDownloadURL(storageRef);
                await updateDoc(colRef, { company_logo_url: url, active: data.active === "true" ? true : false })
            }
        } else {
            await updateDoc(colRef, { active: data.active === "true" ? true : false })
        }
        const success: any = { success: true, message: `Successfully updated` };
        dispatch({ type: API_SUCCESS, payload: success })
    } catch (err: any) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })
    }
    // dispatch({ type: UPDATE_COMPANY_PROFILE, payload: [] })
}


companies.comapnyProfileDelete = (id: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const storage = getStorage()
    try {
        const colRef = doc(getFirebase(), "companies", id);
        const storageRef = ref(storage, `/company_logo/${id}.jpg`);
        await deleteObject(storageRef)
        await updateDoc(colRef, { company_logo_url: null })
        const success: any = { success: true, message: `Successfully Deleted` };
        dispatch({ type: API_SUCCESS, payload: success })
    } catch (err: any) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })
    }
}

companies.branchList = (id: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: API_LOADER, payload: true })

    try {
        const colRef = collection(getFirebase(), `/companies/${id}/branches`);
        const data: any = await getDocs(colRef);
        const list: any = data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: GET_BRANCHES_LIST, payload: list })
        dispatch({ type: API_LOADER, payload: false })
        // dispatch({ type: BRANCH_POSITION_CHECK, payload: "" });
    } catch (err) {
        dispatch({ type: API_LOADER, payload: false })
    }
}

companies.cities = () => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    try {
        const colRef = collection(getFirebase(), "cities");
        const data = await getDocs(colRef);
        const citiList: any = data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: GET_CITI_LIST, payload: citiList });
    } catch (err) {
    }

}


companies.createBranch = (id: string, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const reqObj = {
        name_ar: data.name_ar,
        name_en: data.name_en,
        city: data.city,
        company_name: data.name_en,
        company_uid: id,
        latitude: data.latitude,
        longitude: data.longitude,
        branch_address: data.city.id,
        location_description: data.location_description,
        manager: data.manager,
        status: "active"
    }
    try {
        const colRef = collection(getFirebase(), `/companies/${id}/branches`);
        await addDoc(colRef, reqObj);
        const success: any = { success: true, message: `Successfully created ${data.name_ar}` };
        dispatch({ type: API_SUCCESS, payload: success })
        // dispatch({ type: CREATE_BRANCH, payload: [] })
    } catch (err) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })
    }


}

companies.locationError = () => async (dispatch: any) => {
    const success: any = { success: false, message: "Location on Map is required" }
    dispatch({ type: API_SUCCESS, payload: success })
}


companies.editListBranch = (id: string, branch_uid: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    try {
        const colRef = doc(getFirebase(), `/companies/${id}/branches/`, branch_uid);
        const docSnap = await getDoc(colRef);
        const data: any = docSnap.data();
        dispatch({ type: EDIT_BRANCH_LIST, payload: data })
    } catch (err: any) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })
    }

}

companies.updateBranch = (id: string, branch_uid: string, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {

    const reqObj = {
        latitude: data.latitude,
        longitude: data.longitude,
        update_jobs: true,
        manager: data.manager,
        status: "active"
    }
    try {
        const colRef = doc(getFirebase(), `/companies/${id}/branches/`, branch_uid);
        await updateDoc(colRef, reqObj);
        const success: any = { success: true, message: `Successfully updated` };
        dispatch({ type: API_SUCCESS, payload: success })
        // dispatch({ type: UPDATE_BRANCH, payload: [] })
    } catch (err: any) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })
    }
}

companies.deleteBranch = (id: string, branch_uid: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    try {
        const colRef = doc(getFirebase(), `/companies/${id}/branches/`, branch_uid)
        await deleteDoc(colRef)
        const success: any = { success: true, message: `Successfully Deleted` };
        dispatch({ type: API_SUCCESS, payload: success })

    } catch (err: any) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })

    }

}



companies.branchPositions = (branch_uid: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: API_LOADER, payload: true })
    try {
        const colRef = query(collection(getFirebase(), "positions"), where("branch.id", "==", branch_uid));

        const data: any = await getDocs(colRef);
        const list: any = data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: BRANCHES_POSITION_LIST, payload: list })
        dispatch({ type: API_LOADER, payload: false })
        // dispatch({ type: BRANCH_POSITION_CHECK, payload: "" });
    } catch (err: any) {
        dispatch({ type: API_LOADER, payload: false })
    }

}

companies.branchPositioncheck = (value: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    console.log(value)
    dispatch({ type: BRANCH_POSITION_CHECK, payload: value });
}

companies.positionsList = (id: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: API_LOADER, payload: true })
    try {
        const colRef = query(collection(getFirebase(), "positions"), where("company.id", "==", id));
        const data = await getDocs(colRef);
        const list: any = data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: POSITIONS_LIST, payload: list })
        dispatch({ type: API_LOADER, payload: false })
        dispatch({ type: BRANCH_POSITION_CHECK, payload: "" });
    } catch (err: any) {
        dispatch({ type: API_LOADER, payload: false })
    }

}

companies.createPositions = (id: string, branch_uid: any, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const colRef = collection(getFirebase(), `/companies/${id}/branches`);
    const result = await getDocs(colRef);
    const list: any = result.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
    const newlist: any = [];
    for (const item of list) {
        newlist.push(item.id)
    }
    const reqObj: any = {
        branches_ids: branch_uid ? [branch_uid] : data.branches_ids,
        name_ar: data.name_ar,
        name_en: data.name_en,
        gosi_title: data.gosi_title,
        gosi_id: data.gosi_id,
        age_from: Number(data.age_from),
        age_to: Number(data.age_to),
        gender: data.gender,
        is_health_card_required: data.is_health_card_required === "true" ? true : false,
        as3a_percentage: Number(data.as3a_percentage),
        hourly_rates: Object.values(data.hourly_rates).map((item, i) => Number(item)),
        position_description: data.position_description,
        position_requirements: data.position_requirements,
        company_id: id
    }
    const createPositionCallable = httpsCallable(functions, "createPositionCallable");
    createPositionCallable(reqObj).then((results: any) => {
        const { success } = results.data;
        if (success === true) {
            results.data.message = `Successfully created ${data.name_en}.`
            dispatch({ type: API_SUCCESS, payload: results.data })
        } else {
            dispatch({ type: API_SUCCESS, payload: results.data })
        }
    })

    // dispatch({ type: CREATE_POSITIONS, payload: [] })
}

companies.editListPosition = (position_uid: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    try {
        const colRef = doc(getFirebase(), "positions", position_uid);
        const getSnap = await getDoc(colRef);
        const data: any = getSnap.data();
        dispatch({ type: EDIT_POSITION_LIST, payload: data })
        dispatch({ type: API_SUCCESS, payload: {} })
    } catch (err: any) {
    }

}

companies.updatePositions = (id: string, position_id: string, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    console.log(data)
    const reqObj = {
        position_id: position_id,
        name_ar: data.name_ar,
        name_en: data.name_en,
        gosi_title: data.gosi_title,
        gosi_id: data.gosi_id,
        age_from: Number(data.age_from),
        age_to: Number(data.age_to),
        gender: data.gender,
        is_health_card_required: data.is_health_card_required === "true" ? true : false,
        as3a_percentage: Number(data.as3a_percentage),
        hourly_rates: Object.values(data.hourly_rates).map((item, i) => Number(item)),
        position_description: data.position_description,
        position_requirements: data.position_requirements,
    }
    const updatePositionCallable = httpsCallable(functions, "updatePositionCallable");
    updatePositionCallable(reqObj).then((results: any) => {
        const { success } = results.data;
        if (success === true) {
            results.data.message = `Successfully updated`
            dispatch({ type: API_SUCCESS, payload: results.data })
        } else {
            dispatch({ type: API_SUCCESS, payload: results.data })
        }
    })

    // dispatch({ type: UPDATE_POSITIONS, payload: [] })
}

companies.delatePositions = (position_uid: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    try {
        const colRef = doc(getFirebase(), `positions`, position_uid)
        await deleteDoc(colRef)
        const success: any = { success: true, message: `Successfully Deleted` };
        dispatch({ type: API_SUCCESS, payload: success })

    } catch (err: any) {
        const success: any = { success: false, message: "Invalid inputs" }
        dispatch({ type: API_SUCCESS, payload: success })

    }

}



companies.photosList = (id: string, update?: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    if (!update) {
        dispatch({ type: SUB_PAGE_LOADER, payload: true })
    }

    const storage = getStorage();
    try {
        const storageRef: any = ref(storage, `/companies/${id}/jobs_images`);
        const results: any = await listAll(storageRef);
        const list: any = []
        for (const item in results.items) {
            const url: any = await getDownloadURL(results.items[item]);
            if (url) {
                list[item] = { id: item, img_url: url }
            }
        }
        const subList: any = list.sort((a: any, b: any) => b.img_url.localeCompare(a.img_url));
        dispatch({ type: GET_PHOTOS_LIST, payload: subList })
        dispatch({ type: SUB_PAGE_LOADER, payload: false })
    } catch (err: any) {
        dispatch({ type: SUB_PAGE_LOADER, payload: false })
    }

}



companies.createPhotos = (id: string, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const storage = getStorage();
    try {
        const storageRef: any = ref(storage, `/companies/${id}/jobs_images/${data.name}`);
        const snapshot: any = await uploadString(storageRef, data.doc, "data_url")
        if (snapshot && snapshot.ref) {
            const success: any = { success: true, message: `Successfully uploaded` };
            dispatch({ type: API_SUCCESS, payload: success })
        }
    } catch (err: any) {
    }

}

companies.photosError = () => async (dispatch: any) => {
    const success: any = { success: false, message: "Maximum number of images you can upload is 5" }
    dispatch({ type: API_SUCCESS, payload: success })
}

companies.clearPopup = () => async (dispatch: any) => {
    dispatch({ type: API_SUCCESS, payload: {} })
}





