import React, {FC, useState, useEffect, useRef} from 'react'
import Autocomplete from 'react-google-autocomplete'

import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps'

import Geocode from 'react-geocode'

const {SearchBox}: any = require('react-google-maps/lib/components/places/SearchBox')
// import Autocomplete from 'react-google-autocomplete'

Geocode.setApiKey(`${process.env.REACT_APP_APIKEY}`)
Geocode.enableDebug()

interface Props {
  height: string
  searchLocation?: string
  mapDetails: (data: any) => void
  editObj?: any
}

const Gmap: FC<Props> = ({height, searchLocation, mapDetails, editObj}) => {
  const mapRef: any = useRef(null)

  const [addressObj, setAddressObj] = useState<any>({
    address: '',
    city: '',
    area: '',
    state: '',
    country: '',
    zipCode: '',
    mapPosition: {
      lat: 0,
      lng: 0,
    },
  })

  useEffect(() => {
    // if (searchLocation) {
    //   setLatLng(searchLocation)
    // }
    if (editObj && editObj.lat !== 0 && editObj.lng !== 0) {
      setAddressObj({...addressObj, mapPosition: {...editObj}})
    }
  }, [editObj.lat])

  const setLatLng = async (location: string) => {
    Geocode.fromAddress(location).then((response: any) => {
      const address = response.results[0].formatted_address,
        addressArray = response.results[0].address_components,
        city = getCity(addressArray),
        area = getArea(addressArray),
        state = getState(addressArray),
        country = getCountry(addressArray),
        zipcode = getZipCode(addressArray),
        newLat = response.results[0].geometry.location.lat,
        newLng = response.results[0].geometry.location.lng

      setAddressObj({
        ...addressObj,
        address: address ? address : '',
        city: city ? city : '',
        area: area ? area : '',
        state: state ? state : '',
        country: country ? country : '',
        zipCode: zipcode ? zipcode : '',
        mapPosition: {
          lat: newLat,
          lng: newLng,
        },
      })
    })
  }

  const onMarkarDragEnd = async (event: any) => {
    let newLat: string = event.latLng.lat()
    let newLng: string = event.latLng.lng()
    console.log(newLat, newLng)
    Geocode.fromLatLng(newLat, newLng).then((response: any) => {
      const address = response.results[0].formatted_address,
        addressArray = response.results[0].address_components,
        city = getCity(addressArray),
        area = getArea(addressArray),
        state = getState(addressArray),
        country = getCountry(addressArray),
        zipcode = getZipCode(addressArray),
        newLat = response.results[0].geometry.location.lat,
        newLng = response.results[0].geometry.location.lng
      setAddressObj({
        ...addressObj,
        address: address ? address : '',
        city: city ? city : '',
        area: area ? area : '',
        state: state ? state : '',
        country: country ? country : '',
        zipCode: zipcode ? zipcode : '',
        mapPosition: {
          lat: newLat,
          lng: newLng,
        },
      })
    })
  }

  const getCity = (addressArray: any) => {
    let city = ''

    for (let index = 0; index < addressArray.length; index++) {
      if (
        addressArray[index].types[0] &&
        'administrative_area_level_2' === addressArray[index].types[0]
      ) {
        city = addressArray[index].long_name
        return city
      }
    }
  }

  const getArea = (addressArray: any) => {
    let area = ''
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index].types[0]) {
        for (let j = 0; j < addressArray.length; j++) {
          if (
            'sublocality_level_2' === addressArray[index].types[j] ||
            'locality' === addressArray[index].types[j]
          ) {
            area = addressArray[index].long_name
          }
          return area
        }
      }
    }
  }

  const getState = (addressArray: any) => {
    let state = ''
    for (let index = 0; index < addressArray.length; index++) {
      if (
        addressArray[index].types[0] &&
        'administrative_area_level_1' === addressArray[index].types[0]
      ) {
        state = addressArray[index].long_name
        return state
      }
    }
  }

  const getCountry = (addressArray: any) => {
    let country = ''
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index].types[0] && 'country' === addressArray[index].types[0]) {
        country = addressArray[index].long_name
        return country
      }
    }
  }

  const getZipCode = (addressArray: any) => {
    let zipcode = ''
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index].types[0] && 'postal_code' === addressArray[index].types[0]) {
        zipcode = addressArray[index].long_name
        return zipcode
      }
    }
  }

  mapDetails(addressObj)

  useEffect(() => {
    console.log(mapRef)
  }, [])

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props: any) => (
      <>
        <GoogleMap
          defaultZoom={11}
          defaultMapTypeId={'roadmap'}
          defaultCenter={addressObj.mapPosition}
          defaultOptions={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.TOP_RIGHT,
            },
          }}
        >
          <Marker
            defaultDraggable={true}
            position={addressObj.mapPosition && addressObj.mapPosition}
            onDragEnd={onMarkarDragEnd}
          />
          <Autocomplete
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              // width: `300px`,
              height: `30px`,
              marginTop: `27px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: 'absolute',
              top: '-5%',
              left: '1%',
            }}
            placeholder='Search a location'
            onPlaceSelected={(place: any) => {
              setLatLng(place.formatted_address)
            }}
            options={{
              types: ['(regions)'],
              componentRestrictions: {country: 'sa'},
            }}
          />
        </GoogleMap>
      </>
    ))
  )
  return (
    <MapWithAMarker
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_APIKEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{height: `100%`}} />}
      containerElement={<div style={{height: height}} />}
      mapElement={<div style={{height: `100%`}} />}
    />
  )
}

export default Gmap
