import React, {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/reducers'
import {KTSVG} from '../../../../../_metronic/helpers'
import TextFieldGroup from '../../../../comomn/TextFieldGroup'
import createCompanyValidations from '../../../../comomn/validations/companies/createCompany'

import {companies} from '../../../../redux/actions/companyAction'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

interface Props {
  closeModalRef: any
}

type FormValues = {
  name_en: string
  name_ar: string
  cr_number: string
  employer_labor_office_id: string
  employer_sequence_number: string
  vat_no: string
}

const CreateCompany: FC<Props> = ({closeModalRef}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<FormValues>()
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  useEffect(() => {
    setLoading(false)
    if (success.success) {
      successMsg(success)
      cancelFn()
    }
  }, [success.success])

  const successMsg = (msg: any) => {
    if (msg.success === true) {
      setLoading(false)
      closeModalRef.current.click()
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm',
          cancelButton: 'btn btn-white',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          closeModalRef.current.click()
          dispatch(companies.companyList())
          dispatch(companies.clearPopup())
        }
      })
    } else if (msg.success === false) {
      setLoading(false)
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // setPopup(true)
          // closeModalRef.current.click()
          // setLoading(false)
        }
      })
    }
  }

  const submit = (data: any) => {
    setLoading(true)
    dispatch(companies.createCompany(data))
  }

  const cancelFn = () => {
    console.log('close function')
    reset()
  }

  return (
    <div className='modal fade' id='Create_Companies' aria-hidden='true' ref={closeModalRef}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <form className='form'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='fw-bolder m-0'>Create New Company</h3>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={cancelFn}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
              <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                <div className='row fv-row'>
                  <div className='col-sm-12 mb-5'>
                    <TextFieldGroup
                      label='Name in English'
                      type='text'
                      name='name_en'
                      register={register('name_en', createCompanyValidations.name_en)}
                      error={errors.name_en && errors.name_en.message}
                      isValidate='true'
                      placeholder='Enter Name In English'
                    />
                  </div>
                  <div className='col-sm-12 mb-5'>
                    <TextFieldGroup
                      label='Name in Arabic'
                      type='text'
                      name='name_ar'
                      register={register('name_ar', createCompanyValidations.name_ar)}
                      error={errors.name_ar && errors.name_ar.message}
                      isValidate='true'
                      placeholder='Enter Name in Arabic'
                    />
                  </div>
                  <div className='col-sm-12 mb-5'>
                    <TextFieldGroup
                      label='Certificate Registration (CR)'
                      type='text'
                      name='cr_number'
                      register={register('cr_number', createCompanyValidations.cr_number)}
                      error={errors.cr_number && errors.cr_number.message}
                      isValidate='true'
                      placeholder='Enter Certificate Registration (CR)'
                    />
                  </div>
                  <div className='col-sm-12 mb-5'>
                    <TextFieldGroup
                      label='Labor ID'
                      type='text'
                      name='employer_labor_office_id'
                      register={register(
                        'employer_labor_office_id',
                        createCompanyValidations.employer_labor_office_id
                      )}
                      error={
                        errors.employer_labor_office_id && errors.employer_labor_office_id.message
                      }
                      isValidate='true'
                      placeholder='Enter Labor ID'
                    />
                  </div>
                  <div className='col-sm-12 mb-5'>
                    <TextFieldGroup
                      label='Labor Sequence number'
                      type='text'
                      name='employer_sequence_number'
                      register={register(
                        'employer_sequence_number',
                        createCompanyValidations.employer_sequence_number
                      )}
                      error={
                        errors.employer_sequence_number && errors.employer_sequence_number.message
                      }
                      isValidate='true'
                      placeholder='Enter Labor Sequence number'
                    />
                  </div>
                  <div className='col-sm-12 mb-20'>
                    <TextFieldGroup
                      label='VAT number'
                      type='text'
                      name='vat_no'
                      register={register('vat_no', createCompanyValidations.vat_no)}
                      error={errors.vat_no && errors.vat_no.message}
                      isValidate='true'
                      placeholder='Enter VAT number'
                    />
                  </div>
                  <div className=' col-sm-12 text-center'>
                    <button
                      type='button'
                      className='btn btn-light me-3'
                      data-bs-dismiss='modal'
                      onClick={cancelFn}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      onClick={handleSubmit(submit)}
                      className='btn btn-bg-primary btn-color-white'
                    >
                      Submit
                      {loading ? (
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      ) : (
                        ''
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateCompany
