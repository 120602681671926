import React, {FC, useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {companies} from '../../../../redux/actions/companyAction'
import {RootState} from '../../../../redux/reducers'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const Photos: FC = () => {
  const subPageLoader = useSelector<RootState>(({auth}) => auth.subPageLoader)
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const images: any = useSelector<RootState>(({companies}) => companies.photos)

  const [photoList, setPhotoList] = useState<any>([])

  const params: any = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(companies.photosList(params.id))
  }, [])

  useEffect(() => {
    if (success.success === true || success.success === false) {
      successMsg(success)
    }
  }, [success.success])

  const successMsg = (msg: any) => {
    if (msg.success === true) {
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm',
          cancelButton: 'btn btn-white',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(companies.photosList(params.id, 'upload'))
          dispatch(companies.clearPopup())
        }
      })
    } else if (msg.success === false) {
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(companies.clearPopup())
        }
      })
    }
  }

  const onchange = (e: any) => {
    const {name: value, files} = e.target
    if (files) {
      let render = new FileReader()
      render.readAsDataURL(files[0])
      render.onload = () => {
        if (render.readyState === 2) {
          const obj: any = {
            name: files[0].name,
            type: files[0].type,
            doc: render.result,
          }
          if (images.length < 5) {
            dispatch(companies.createPhotos(params.id, obj))
          } else {
            dispatch(companies.photosError())
          }
        }
      }
    }
  }

  const imageview = (item: any, i: number) => {
    return (
      <img
        key={item.img_url}
        src={toAbsoluteUrl(`${item.img_url}`)}
        style={{width: '150px', height: '150px'}}
        alt=''
        className='w-100 rounded'
      />
    )
  }

  return (
    <>
      <h1 className='d-flex align-items-center text-dark-300 fw-bolder fs-3'>Photos</h1>
      <div className='card card-xxl-stretch mb-5 mb-xxl-8 pt-5'>
        <div className='card-body py-2'>
          <div className='row fv-row'>
            {subPageLoader === true ? (
              <div className='splash-screen rounded'>
                <span className='spinner-border spinner-border-lg text-primary align-middle'></span>
                <span>Loading ...</span>
              </div>
            ) : (
              ''
            )}
            {images.length > 0 &&
              images.map((item: any, i: any) => (
                <div className='col-lg-2' key={i}>
                  <div className='card card-custom overlay overflow-hidden mb-10'>
                    <div className='card-body p-0'>
                      <div className='overlay-wrapper w-150px h-150px'>{imageview(item, i)}</div>
                      <div
                        className='overlay-layer align-center w-150px h-150px rounded'
                        style={{
                          background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
                        }}
                      >
                        {/* <span className='svg-icon svg-icon-1x svg-icon-white'>
                          <KTSVG path='/as3a-media\svg\view.svg' className='svg-icon-1' />
                        </span> */}
                        {/* <span className='btn btn-primary btn-sm btn-shadow'>view</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div className='col-sm-2'>
              <div
                className='image-input border-primary border-dashed'
                style={{backgroundColor: '#F5F8FA'}}
                data-kt-image-input='true'
              >
                <div className='image-input-wrapper w-150px h-150px'></div>
                <label
                  className='btn btn-icon btn-rounded btn-color-primary w-150px h-150px'
                  style={{position: 'absolute', top: '0', right: '0'}}
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title=''
                  data-bs-original-title='Change avatar'
                >
                  <span className='svg-icon svg-icon-3x mb-5'>
                    <KTSVG path='/as3a-media/DuoTune_Pro/svg/fil010.svg' className='svg-icon-1' />
                    <div className='text-primary py-2'>Upload new Photo</div>
                  </span>
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg'
                    onChange={(e) => onchange(e)}
                    // disabled={images.length < 9 ? false : true}
                  />
                  <input type='hidden' name='avatar_remove' />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Photos
