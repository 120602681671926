import { GET_NUMBER_OF_HOURS } from '../actions/types'

const initialState = {
    numberOfHours: []
}

const dashboardReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_NUMBER_OF_HOURS: return { ...state, numberOfHours: action.payload };
        default: return state;
    }
}

export default dashboardReducer;