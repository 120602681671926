import {
    GET_USER_LIST,
    RESET_PASSWORD,
    GET_USER_PERMISSIONS,
    API_ERROR, API_SUCCESS,
    API_LOADER, PAGE_LOADER,
    SUB_PAGE_LOADER,
    BRANCH_POSITION_CHECK,
    POSITION_BRANCH_CHECK,
    BRANCH_POSITION_CREATE_CHECK
} from '../actions/types'

const initialState: any = {
    users: {},
    permissions: [],
    error: {},
    success: {},
    resrtPassword: false,
    apiLoader: false,
    pageLoader: false,
    subPageLoader: false,
    branch_uid: "",
    positionBranchCheck: false,
    branchpositioncreatecheck: false
}

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USER_LIST:
            return { ...state, users: action.payload };
        case API_ERROR:
            return { ...state, error: { error: action.payload } };
        case API_SUCCESS:
            return { ...state, success: action.payload };
        case GET_USER_PERMISSIONS:
            return { ...state, permissions: action.payload };
        case RESET_PASSWORD:
            return { ...state, resrtPassword: action.payload };
        case API_LOADER: return { ...state, apiLoader: action.payload };
        case PAGE_LOADER: return { ...state, pageLoader: action.payload };
        case SUB_PAGE_LOADER: return { ...state, subPageLoader: action.payload }
        case BRANCH_POSITION_CHECK: return { ...state, branch_uid: action.payload };
        case POSITION_BRANCH_CHECK: return { ...state, positionBranchCheck: action.payload };
        case BRANCH_POSITION_CREATE_CHECK: return { ...state, branchpositioncreatecheck: action.payload }
        default: return state;
    }
}

export default authReducer;