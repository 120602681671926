// api response types
export const API_ERROR = "API_ERROR";
export const API_SUCCESS = "API_SUCCESS";
export const API_LOADER = "API_LOADER";
export const PAGE_LOADER = "PAGE_LOADER";
export const SUB_PAGE_LOADER = "SUB_PAGE_LOADER";

// all auth module  types
export const CREATE_USERS = "CREATE_USERS";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const RESET_PASSWORD = "RESET_PASSWORD";
//all users module types
export const GET_ALL_USERS_LIST = "GET_ALL_USERS_LIST";
// all companies module  types
export const GET_ALL_COMPANIES_LIST = "GET_ALL_COMPANIES_LIST";
export const CREATE_COMPANIES = "CREATE_COMPANIES";
export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const COMPANY_PROFILE_LIST = "COMPANY_PROFILE";
export const UPDATE_COMPANY_PROFILE = "UPDATE_COMPANY_PROFILE";
export const GET_BRANCHES_LIST = "GET_BRANCHES_LIST";
export const GET_CITI_LIST = "GET_CITI_LIST";
export const CREATE_BRANCH = "CREATE_BRANCH";
export const EDIT_BRANCH_LIST = "EDIT_BRANCH_LIST";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const BRANCHES_POSITION_LIST = "BRANCHES_POSITION_LIST";
export const BRANCH_POSITION_CHECK = "BRANCH_POSITION_CHECK";
export const POSITION_BRANCH_CHECK = "POSITION_BRANCH_CHECK";
export const BRANCH_POSITION_CREATE_CHECK = "BRANCH_POSITION_CREATE_CHECK"
export const POSITIONS_LIST = "POSITIONS_LIST";
export const CREATE_POSITIONS = "CREATE_POSITIONS";
export const EDIT_POSITION_LIST = "EDIT_POSITION_LIST";
export const UPDATE_POSITIONS = "UPDATE_POSITIONS";
export const GET_PHOTOS_LIST = "GET_PHOTOS_LIST";

//all dashboard module  types
export const GET_NUMBER_OF_HOURS = " GET_NUMBER_OF_HOURS";

