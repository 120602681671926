import React, {FC, useState, useEffect} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import PrivateRoutes from './PrivateRoutes'
import AuthPage from '../components/auth/AuthPage'
import {RootState} from '../redux/reducers'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import isEmpty from '../comomn/utils/isEmpty'
import {users} from '../redux/actions/authAction'

const Routes: FC = () => {
  const auth: any = useSelector<RootState>((state) => state.auth, shallowEqual)
  const isAuthorized = auth.users && auth.users.uid ? true : false

  const dispatch = useDispatch()

  useEffect(() => {
    if (auth.users.uid) {
      dispatch(users.permissions(auth.users.uid))
    } else {
      dispatch(users.permissions())
    }
  }, [auth.users])

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from='/auth' to='/' />
        )}

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/signin' />
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export default Routes
