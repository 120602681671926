import React from 'react'
import ReactDOM from 'react-dom'

import {Provider} from 'react-redux'
import {store, persistor, rrrProps} from './app/redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
// import './_metronic/assets/sass/core/components/_variables.scss'
// import './_metronic/assets/sass/core/components/_modal.scss'

import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core css
import 'primeicons/primeicons.css' //icons

import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
const {PUBLIC_URL} = process.env

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrrProps}>
          <App basename={PUBLIC_URL} />
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
