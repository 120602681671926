import { GET_ALL_USERS_LIST, API_SUCCESS, API_ERROR, API_LOADER } from './types';
import axios from 'axios'
import { getAuth } from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";

import { collection, doc, getDocs, setDoc, addDoc, query, where, updateDoc, orderBy, startAt, deleteDoc, Timestamp } from "firebase/firestore";

import { functions } from '../../config/fbConfig';

const auth = getAuth();

export const userList: any = {};

userList.allUsers = () => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: API_LOADER, payload: true })
    try {
        const q = query(collection(getFirebase(), "all_users"), where("user_type", "==", "as3a"), where("status", "==", "active"))
        const data: any = await getDocs(q)
        const user: any = data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: GET_ALL_USERS_LIST, payload: user })
        dispatch({ type: API_SUCCESS, payload: {} })
        dispatch({ type: API_LOADER, payload: false })
    } catch (err: any) {
        dispatch({ type: API_ERROR, payload: err });
        dispatch({ type: API_LOADER, payload: false })
    }


}


userList.addUser = (data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const userObj: any = {
        email: data.email,
        name: data.name_en,
        user_permissions: data.permissions
    }
    const createAs3aUserCallable = httpsCallable(functions, 'createAs3aUserCallable');
    createAs3aUserCallable(userObj).then((results: any) => {
        const { success } = results.data;
        if (success === true) {
            results.data.message = `Successfully created ${data.name_en}.`
            dispatch({ type: API_SUCCESS, payload: results.data })
        } else {
            dispatch({ type: API_SUCCESS, payload: results.data })
        }


    })

}

userList.userById = (id: string, data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const userObj: any = {
        user_id: id,
        action: "update",
        name: data.name_en,
        user_permissions: data.permissions
    }
    const updateAs3aUserCallable = httpsCallable(functions, 'updateAs3aUserCallable');
    updateAs3aUserCallable(userObj).then((results: any) => {
        const { success } = results.data;
        if (success === true) {
            results.data.message = "Successfully updated."
            dispatch({ type: API_SUCCESS, payload: results.data })
        } else {
            dispatch({ type: API_SUCCESS, payload: results.data })
        }

    })
}

userList.userbyDelete = (data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const userObj: any = {
        user_id: data.id,
        action: "delete"
    }
    const updateAs3aUserCallable = httpsCallable(functions, 'updateAs3aUserCallable');
    updateAs3aUserCallable(userObj).then((results: any) => {
        const { success } = results.data;
        if (success === true) {
            results.data.message = `Successfully Deleted.`
            dispatch({ type: API_SUCCESS, payload: results.data })
        } else {
            dispatch({ type: API_SUCCESS, payload: results.data })
        }

    })

}