import React, {FC, useState, useEffect, createRef} from 'react'
import {
  Redirect,
  Route,
  Switch,
  Link,
  withRouter,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {DefauktModel} from '../../../_metronic/partials/widgets'
import {RootState} from '../../redux/reducers'

import {companies} from '../../redux/actions/companyAction'

import CompanyProfile from './components/companyProfile/CompanyProfile'
import CreateCompanies from './components/companyProfile/CreateCompanies'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Companies',
    path: '/companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Companies: FC = () => {
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const pageLoader: any = useSelector<RootState>(({auth}) => auth.pageLoader)
  const companiesList: any = useSelector<RootState>(({companies}) => companies.companyList)
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)

  const [companylist, setcompanyList] = useState<any>([])

  const closeModalRef: any = createRef()

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (companiesList.length > 0) {
      setcompanyList(companiesList)
    } else {
      dispatch(companies.companyList())
    }
  }, [companiesList])

  const onchange = (e: any) => {
    let value: string = e.target.value
    const newList: any = companiesList.filter((x: any, i: number) =>
      x.name_en.match(x.name_en === value.toLocaleUpperCase() ? value.toLocaleUpperCase() : value)
    )
    setcompanyList(newList)
  }

  const limitText = (string = '', limit = 0) => {
    return string.substring(0, limit)
  }

  // const greeting: any = limit('Hello Marcus', 100)

  return (
    <div className='row gy-5 g-xl-8'>
      {location.pathname === '/companies' ? (
        <div className='card bg-light card-xxl-stretch mb-5 mb-xxl-8'>
          {pageLoader === true ? (
            <div className='splash-screen rounded'>
              <span className='spinner-border spinner-border-lg text-primary align-middle'></span>
              <span>Loading ...</span>
            </div>
          ) : (
            ''
          )}
          <div className='card-body py-3'>
            <div className='row fv-row'>
              <div className='col-sm-12'>
                <div className='d-flex flex-stack mb-5'>
                  <div className='d-flex align-items-center position-relative my-1'>
                    <DefauktModel />
                  </div>
                  <div className='d-flex justify-content-end' data-kt-docs-table-toolbar='base'>
                    <div className='d-flex align-items-center position-relative me-4'>
                      <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          className='mh-50px'
                        >
                          <rect
                            opacity='0.5'
                            x='17.0365'
                            y='15.1223'
                            width='8.15546'
                            height='2'
                            rx='1'
                            transform='rotate(45 17.0365 15.1223)'
                            fill='black'
                          ></rect>
                          <path
                            d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                            fill='black'
                          ></path>
                        </svg>
                      </span>
                      <input
                        type='text'
                        id='kt_filter_search'
                        className='form-control form-control-white w-250px ps-15'
                        style={{border: 'none'}}
                        placeholder='Search Company'
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {permissions.length > 0 &&
                    permissions[0]['permissions']['companies'].create_company === true ? (
                      <button
                        type='button'
                        className='btn btn-sm btn-bg-primary btn-color-white fw-bolder'
                        data-bs-toggle='modal'
                        data-bs-target='#Create_Companies'
                      >
                        <span className='svg-icon svg-icon-white me-1 '>
                          <KTSVG
                            path='/as3a-media/DuoTune_Pro/svg/arr009.svg'
                            className='w-30px h-30px'
                          />
                        </span>
                        Create New Company
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              {companylist.length > 0
                ? companylist.map((item: any, index: number) => (
                    <div className='col-sm-3 mb-10' key={index}>
                      <div className='card card-custom overlay overflow-hidden pt-5'>
                        <Link to={`/companies/companyProfile/${item.id}`}>
                          <div className='card-body text-center pt-5'>
                            {/* <div className=' p-5'>
                              {item.company_logo_url !== null ? (
                                <img
                                  src={toAbsoluteUrl(`${item.company_logo_url}`)}
                                  alt=''
                                  className='w-70px h-50px rounded'
                                />
                              ) : (
                                <img
                                  alt=''
                                  src={toAbsoluteUrl('/media/avatars/blank.png')}
                                  className='w-70px h-50px rounded'
                                />
                              )}
                            </div> */}
                            {/* <div className='w-100 text-center'> */}
                            {item.company_logo_url !== null ? (
                              <div className='w-100 p-0 px-1 py-1 mb-5 text-center'>
                                <div className='px-15'>
                                  <img
                                    alt=''
                                    src={toAbsoluteUrl(`${item.company_logo_url}`)}
                                    // style={{border: '1px solid'}}
                                    className='rounded h-50px'
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className='w-100 p-0 px-1 py-1 mb-5 text-center'>
                                <div className='px-15'>
                                  <img
                                    alt=''
                                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                                    className='rounded h-50px'
                                  />
                                </div>
                              </div>
                            )}
                            {/* </div> */}

                            <h2
                              className='text-gray-900 text-hover-primary fs-3 fw-bolder mb-5 token-anchor'
                              data-bs-toggle='tooltip'
                              data-bs-placement='bottom'
                              title={`${item.name_en}`}
                            >
                              {/* {limitText(item.name_en, 30)} */}
                              {item.name_en}
                            </h2>
                            {item.active ? (
                              <span className='badge badge-light-success fs-7 fw-bolder mb-5'>
                                Active
                              </span>
                            ) : (
                              <span className='badge badge-light-danger fs-7 fw-bolder mb-5'>
                                Inactive
                              </span>
                            )}
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          </div>
          <CreateCompanies closeModalRef={closeModalRef} />
        </div>
      ) : (
        <>
          <Switch>
            <Route path='/companies/companyProfile/:id'>
              <PageTitle breadcrumbs={accountBreadCrumbs}>CompanyProfile</PageTitle>
              <CompanyProfile />
            </Route>
            <Redirect from='/companies' exact={true} to='/companies' />
            <Redirect to='/companies' />
          </Switch>
        </>
      )}
    </div>
  )
}

export default Companies
