const SigninValidation = {
    email: {
        required: {
            value: true,
            message: "email is required.",
        },

    },
    password: {
        required: {
            value: true,
            message: "password is required.",
        },
        //   pattern:{
        //     value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        //     message: "Enter valid password",
        //   },
    }
}

export default SigninValidation;