import React, {FC, useState, useEffect, createRef} from 'react'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useForm} from 'react-hook-form'
import CheckBox from '../../../../comomn/CheckBox'
import Radio from '../../../../comomn/Radio'
import {RootState} from '../../../../redux/reducers'
import {companies} from '../../../../redux/actions/companyAction'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

interface Props {
  profileObj: any
  setIscheckLogo: any
}

type FormValues = {
  active: string
}

const EditCompanyProfile: FC<Props> = ({profileObj, setIscheckLogo}) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: {errors},
    reset,
  } = useForm<FormValues>()
  const success: any = useSelector<RootState>(({auth}) => auth.success)

  const [company_logo_url, setCompany_logo_url] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [isDeleteLogo, setIsDeleteLogo] = useState<boolean>(false)

  const closeModalRef: any = createRef()

  const params: any = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (profileObj.company_name) {
      const {active} = profileObj
      let isActive: string = active === true ? 'true' : 'false'
      setValue('active', isActive)
    }
    if (success.success) {
      successMsg(success)
    }
  }, [profileObj.company_name, success.success])

  const successMsg = (msg: any) => {
    if (msg.success === true) {
      setIscheckLogo(true)
      setLoading(false)
      closeModalRef.current.click()
      setCompany_logo_url({})
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm',
          cancelButton: 'btn btn-white',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(companies.clearPopup())
        }
      })
    } else if (msg.success === false) {
      setLoading(false)
      MySwal.fire({
        html: `${msg.error && msg.error.errorInfo ? msg.error.errorInfo.message : msg.error}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // setPopup(true)
          // closeModalRef.current.click()
          // setLoading(false)
        }
      })
    }
  }

  const onchange = (e: any) => {
    const {name, value, files} = e.target
    if (files) {
      let render = new FileReader()
      render.readAsDataURL(files[0])
      render.onload = () => {
        if (render.readyState === 2) {
          const obj: any = {
            name: files[0].name,
            type: files[0].type,
            doc: render.result,
          }
          setCompany_logo_url(obj)
        }
      }
    }
  }

  const submit = (data: any) => {
    data.company_logo_url = company_logo_url
    setLoading(true)
    dispatch(companies.companyProfileUpdate(params.id, data))
  }

  const deleteImg = () => {
    MySwal.fire({
      html: `Are you sure you want to delete Company logo ?`,
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      padding: '2.25rem',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-danger btn-sm me-2',
        cancelButton: 'btn btn-white',
        icon: 'text-warning border-warning mb-5',
        container: 'py-7',
      },
      showClass: {
        popup: 'swal2-show',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show',
      },
      hideClass: {
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeleteLogo()
      }
    })
  }

  const confirmDeleteLogo = () => {
    setIsDeleteLogo(true)
    setIscheckLogo(false)
    dispatch(companies.comapnyProfileDelete(params.id))
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_company_profile_Edit'
      aria-hidden='true'
      ref={closeModalRef}
    >
      <div className='modal-dialog modal-dialog-centered mw-500px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='fw-bolder m-0'>Edit company details</h3>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='row fv-row mb-10'>
                <div className='col-sm-12 mb-10' style={{borderBottom: '1px dashed #eff2f5'}}>
                  <h5 className='fw-bolder m-0 mb-10'>Company configurations</h5>
                  <div className='row fv-row mb-5'>
                    <div className='col-sm-3'>Status</div>
                    <div className='col-sm-3'>
                      <Radio
                        label='Active'
                        type='radio'
                        value='true'
                        register={register('active')}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <Radio
                        label='Inactive'
                        type='radio'
                        value='false'
                        register={register('active')}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 mb-20'>
                  <div className='row fv-row'>
                    <h5 className='fw-bolder m-0 mb-10'>Change Company Logo</h5>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                      >
                        {company_logo_url && company_logo_url.doc ? (
                          <img
                            src={toAbsoluteUrl(`${company_logo_url.doc}`)}
                            alt=''
                            className='image-input-wrapper w-125px h-125px'
                          />
                        ) : profileObj && profileObj.company_logo_url ? (
                          <img
                            src={toAbsoluteUrl(`${profileObj.company_logo_url}`)}
                            alt=''
                            className='image-input-wrapper w-125px h-125px'
                          />
                        ) : (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: `url(${toAbsoluteUrl(`/media/avatars/blank.png`)})`,
                            }}
                          ></div>
                        )}

                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title=''
                          data-bs-original-title='Change avatar'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>
                          <input type='file' accept='.png, .jpg, .jpeg' onChange={onchange} />
                          <input type='hidden' name='avatar_remove' />
                        </label>

                        {/* <span
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='cancel'
                          data-bs-toggle='tooltip'
                          title=''
                          data-bs-original-title='Cancel avatar'
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span> */}

                        {profileObj && profileObj.company_logo_url ? (
                          <span
                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='remove'
                            // data-bs-toggle='tooltip'
                            title=''
                            // data-bs-original-title='Remove avatar'
                            onClick={() => deleteImg()}
                          >
                            <i className='bi bi-x fs-2'></i>
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 text-center'>
                  <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn  btn-bg-primary btn-color-white fw-bolder'
                    onClick={handleSubmit(submit)}
                  >
                    Submit
                    {loading ? (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    ) : (
                      ''
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='modal-footer'>
          
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default EditCompanyProfile
