const AddUserValidations = {
    name_en: {
        required: {
            value: true,
            message: "name is required",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid user name",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }

    },
    email: {
        required: {
            value: true,
            message: "email is required",
        },
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Please enter valid email Id",
        },

    },

}

export default AddUserValidations;