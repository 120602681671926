import React, {FC, useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation, useHistory, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useForm, Controller} from 'react-hook-form'
import TextFieldGroup from '../../../../comomn/TextFieldGroup'
import CheckBox from '../../../../comomn/CheckBox'
import Radio from '../../../../comomn/Radio'
import {companies} from '../../../../redux/actions/companyAction'
import {users} from '../../../../redux/actions/authAction'
import CreatePositionsValidations from '../../../../comomn/validations/companies/positions/createPositions'
import {RootState} from '../../../../redux/reducers'
import Select, {StylesConfig} from 'react-select'
import {MultiSelect} from 'primereact/multiselect'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

type FormValues = {
  branches_ids: string
  name_ar: string
  name_en: string
  gosi_title: string
  gosi_id: string
  age_from: number
  age_to: number
  gender: string
  is_health_card_required: boolean
  as3a_percentage: number
  hourly_rates: {
    sun: number
    mon: number
    tue: number
    wed: number
    thu: number
    fri: number
    sat: number
  }
  position_description: string
  position_requirements: string
}

const CreatePosition: FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: {errors},
    reset,
  } = useForm<FormValues>()
  const branch_uid: any = useSelector<RootState>(({auth}) => auth.branch_uid)
  const branchpositioncreatecheck: any = useSelector<RootState>(
    ({auth}) => auth.branchpositioncreatecheck
  )
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const positionEdit: any = useSelector<RootState>(({companies}) => companies.positionEdit)
  const branchesList: any = useSelector<RootState>(({companies}) => companies.branchList)
  const positionBranchCheck: any = useSelector<RootState>(({auth}) => auth.positionBranchCheck)

  const [loading, setLoading] = useState<boolean>(false)
  const [selectBranch, setSelectBranch] = useState<any>([])
  const [testOptions, setTestOptions] = useState<any>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const params: any = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.position_uid) {
      dispatch(companies.editListPosition(params.position_uid))
      if (positionEdit.name_en) {
        const {
          name_ar,
          name_en,
          gosi_title_id,
          gosi_title_name,
          requirements,
          hourly_pricing,
          position_description,
          position_requirement_description,
        } = positionEdit

        const hourly_rates: any = {
          sun: 0,
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
        }
        const val: any = Object.values(hourly_pricing.employer_rates)
        Object.keys(hourly_rates).map((item: any, i) => (hourly_rates[item] = val[i]))
        setValue('hourly_rates', hourly_rates)
        const values: any = Object.values(requirements)
        Object.keys(requirements).map((item: any, i) => setValue(item, `${values[i]}`))
        const {branch, city} = positionEdit
        if (positionBranchCheck === true) {
          const copyBranch: any = Array.from(selectBranch)
          copyBranch[0] = {
            name: `${city.name_en} | ${branch.name_en}`,
            code: branch.name_en,
            ...branch,
            city,
          }
          setSelectBranch(copyBranch)
          setValue('branches_ids', copyBranch[0].code)
          dispatch(companies.branchList(params.id))
        } else {
          // setSelectBranch([])
        }
        setValue('name_ar', name_ar)
        setValue('name_en', name_en)
        setValue('gosi_id', gosi_title_id)
        setValue('gosi_title', gosi_title_name)
        setValue('as3a_percentage', 20)
        setValue('position_description', position_description)
        setValue('position_requirements', position_requirement_description)
      }
    } else {
      dispatch(companies.branchList(params.id))
      reset()
      setValue('as3a_percentage', 20)
    }

    if (success.success === true || success.success === false) {
      setLoading(false)
      let redirectPath: string = ''
      if (branch_uid !== '') {
        redirectPath = `/companies/companyProfile/${params.id}/branch/${branch_uid}`
      } else {
        redirectPath = `/companies/companyProfile/${params.id}/positions`
      }
      history.push(redirectPath)
    }
  }, [params.position_uid, success.success, positionEdit.name_en, positionBranchCheck])

  const optionsFn = () => {
    const options: any = []
    branchesList.forEach((item: any, i: number) => {
      return (options[i] = {
        name: `${item.city.name_en} | ${item.name_en} `,
        code: item.name_en,
        ...item,
      })
    })
    return options.sort((a: any, b: any) => a.name_en.localeCompare(b.name_en))
  }

  const handelChange = (value: any) => {
    const branch: any = value
    console.log(branch)

    setSelectBranch(branch)
    // setTestOptions(branch)
    branchValFn()
  }

  const removeOption = (index: number) => {
    const removeArr: any = Array.from(selectBranch)

    removeArr.splice(index, 1)
    setSelectBranch(removeArr)
    if (removeArr.length === 0) {
      setValue('branches_ids', '')
    }
    potionList()
  }

  const potionList = () => {
    const unicBranch: any = Array.from(new Set(selectBranch.map((item: any) => item.id))).map(
      (id: any) => {
        return selectBranch.find((item: any) => item.id === id)
      }
    )

    return unicBranch.length > 0
      ? unicBranch.map((item: any, i: number) => (
          <div className='col-sm-3 p-1' key={i}>
            <div
              key={i}
              className='toast show w-100 border-0'
              role='alert'
              aria-live='assertive'
              aria-atomic='true'
              style={{backgroundColor: 'transparent', boxShadow: 'none'}}
            >
              <div
                className='toast-header rounded'
                style={{
                  backgroundColor: '#F0F3F5',
                  color: 'hsl(0, 0%, 20%)',
                }}
              >
                <strong className='me-auto'>
                  <span className='fw-bold fs-6 me-1'> {item.city.name_en} </span>{' '}
                  <span className='fw-bold fs-6 me-1' style={{color: '#A2A5B5'}}>
                    |
                  </span>
                  <span className='fw-bold fs-6'> {item.name_en}</span>
                </strong>
                <small></small>
                <span
                  className='btn btn-light btn-active-light-primary p-0 bg-transparent svg-icon svg-icon-gray me-1 '
                  onClick={() => removeOption(i)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </span>
              </div>
            </div>
          </div>
        ))
      : ''
  }

  const submit = (data: any) => {
    setLoading(true)
    const branchIds: any = []
    if (selectBranch.length > 0) {
      const unicBranchList: any = Array.from(new Set(selectBranch.map((item: any) => item.id))).map(
        (id: any) => {
          return selectBranch.find((item: any) => item.id === id)
        }
      )
      for (const item of unicBranchList) {
        branchIds.push(item.id)
      }
    }
    data.branches_ids = branchIds
    console.log(data)

    dispatch(companies.createPositions(params.id, branch_uid, data))
  }

  const updateFn = (data: any) => {
    setLoading(true)
    dispatch(companies.updatePositions(params.id, params.position_uid, data))
  }

  const branchValFn = () => {
    let value: string = getValues('branches_ids')
    let isCheck: boolean = selectBranch.length > 0 ? false : true
    return isCheck
  }

  const panelHeaderTemplate = (options: any) => {
    return (
      <div className={options.className}>
        <div className='p-checkbox p-component me-2'>
          <div className='p-hidden-accessible'>
            <input type='checkbox' checked={options.checked} onChange={options.onChange} />
          </div>
          {options.checked === true ? (
            <div
              className='p-checkbox-box p-highlight'
              role='checkbox'
              aria-checked='true'
              onClick={() => selectAllFn(options)}
            >
              <span className='p-checkbox-icon p-c pi pi-check'></span>
            </div>
          ) : (
            <div
              className='p-checkbox-box'
              role='checkbox'
              aria-checked='false'
              onClick={() => selectAllFn(options)}
            ></div>
          )}
        </div>
        <span className='me-2 fw-bold'>Select all</span>
        <button
          type='button'
          className={options.closeElementClassName}
          onClick={options.onCloseClick}
        >
          <span className={options.closeIconClassName}></span>
        </button>
      </div>
    )
  }

  const selectAllFn = (e: any) => {
    // console.log(e)
    if (e.checked === false) {
      setSelectBranch(optionsFn())
      setSelectAll(true)
    } else if (e.checked === true) {
      setSelectBranch([])
      setSelectAll(false)
    }
  }

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className='card card-xxl-stretch mb-5 mb-xxl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              {(params && !params.position_uid) ||
              (params && params.position_uid && positionBranchCheck === true) ? (
                <span className='card-label fw-bolder fs-3 mb-1'>Create Position</span>
              ) : (
                <span className='card-label fw-bolder fs-3 mb-1'>Edit Position</span>
              )}
            </h3>
          </div>
          <div className='card-body py-3'>
            <form>
              <div className='row fv-row mb-5'>
                <div className='col-sm-10'>
                  {(params && !params.position_uid && branchpositioncreatecheck === false) ||
                  (params && params.position_uid && positionBranchCheck === true) ? (
                    <div className='row fv-row mb-5 border-bottom-dashed border-gray-200'>
                      <div className='col-sm-3 mb-10'>
                        <label className='form-label py-2 '>
                          Select branches <span className='required'></span>
                        </label>
                      </div>
                      <div className='col-sm-9 mb-10'>
                        <Controller
                          control={control}
                          name='branches_ids'
                          render={({onChange, value, name, ref}: any) => (
                            <MultiSelect
                              value={selectBranch}
                              options={optionsFn()}
                              onChange={(e) => {
                                handelChange(e.value)
                                // setSelectBranch(e.value)
                                setSelectAll(e.value.length === optionsFn().length)
                              }}
                              // value={testOptions}
                              optionLabel='name'
                              placeholder='Select Branches'
                              className='w-100'
                              style={{backgroundColor: '#eef1f5'}}
                              // showSelectAll={true}
                              // selectAll={true}
                              panelHeaderTemplate={panelHeaderTemplate}
                              selectAll={selectAll}
                              onSelectAll={(e) => selectAllFn(e)}
                            />
                          )}
                          rules={
                            branchValFn()
                              ? CreatePositionsValidations.branches_ids
                              : {required: false}
                          }
                        />

                        {selectBranch.length > 0 ? (
                          <div className='row fv-row py-2'>{potionList()}</div>
                        ) : (
                          ''
                        )}

                        {errors.branches_ids && errors.branches_ids.message && (
                          <div className='fv-plugins-message-container mb-5'>
                            <span className='text-danger ls-1'>{errors.branches_ids.message}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='row fv-row mb-5 border-bottom-dashed border-gray-200'>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>
                        Title in Arabic <span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        placeholder='Enter Position Title in Arabic'
                        type='text'
                        name='name_ar'
                        register={register('name_ar', CreatePositionsValidations.name_ar)}
                        error={errors.name_ar && errors.name_ar.message}
                        // value={''}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>
                        Title in English<span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        placeholder='Enter Position Title in English'
                        type='text'
                        name='name_en'
                        register={register('name_en', CreatePositionsValidations.name_en)}
                        error={errors.name_en && errors.name_en.message}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>
                        GOSI Title<span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        placeholder='Enter GOSI Title'
                        type='text'
                        name='gosi_title'
                        register={register('gosi_title', CreatePositionsValidations.gosi_title)}
                        error={errors.gosi_title && errors.gosi_title.message}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>
                        GOSI ID<span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        placeholder='Enter GOSI ID'
                        type='text'
                        name='gosi_id'
                        register={register('gosi_id', CreatePositionsValidations.gosi_id)}
                        error={errors.gosi_id && errors.gosi_id.message}
                      />
                    </div>
                  </div>
                  <div className='row fv-row mb-5 border-bottom-dashed border-gray-200'>
                    <div className='col-sm-12'>
                      <div className='row fv-row'>
                        <div className='col-sm-3'>
                          <label className='form-label py-3'>
                            Age range<span className='required'></span>
                          </label>
                        </div>
                        <div className='col-sm-1 '>
                          <label className='form-label py-3'>From</label>
                        </div>
                        <div className='col-sm-2'>
                          <TextFieldGroup
                            placeholder='Enter Age'
                            type='text'
                            name='age_from'
                            register={register('age_from', CreatePositionsValidations.age_from)}
                            error={errors.age_from && errors.age_from.message}
                          />
                        </div>
                        <div className='col-sm-1'>
                          <label className='form-label py-3 px-8'>To</label>
                        </div>
                        <div className='col-sm-2'>
                          <TextFieldGroup
                            placeholder='Enter Age'
                            type='text'
                            name='age_to'
                            register={register('age_to', {
                              required: {
                                value: true,
                                message: 'Age is required',
                              },
                              pattern: {
                                value: /^[0-9\s]+$/,
                                message: 'Please enter only digits',
                              },
                              min: {
                                value: getValues('age_from'),
                                message: `please enter min age ${getValues('age_from')}`,
                              },
                            })}
                            error={errors.age_to && errors.age_to.message}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 mb-5'>
                      <div className='row fv-row'>
                        <div className='col-sm-3'>
                          <label className='form-label py-2'>
                            Gender<span className='required'></span>
                          </label>
                        </div>
                        <div className='col-sm-9'>
                          <div className='row fv-row'>
                            <div className='col-sm-2'>
                              <Radio
                                label='Male'
                                type='radio'
                                value='male'
                                register={register('gender', CreatePositionsValidations.gender)}
                              />
                            </div>
                            <div className='col-sm-2'>
                              <Radio
                                label='Female'
                                type='radio'
                                value='female'
                                register={register('gender')}
                              />
                            </div>
                            <div className='col-sm-2'>
                              <Radio
                                label='Both'
                                type='radio'
                                value='both'
                                register={register('gender')}
                              />
                            </div>
                            {errors.gender && errors.gender.message && (
                              <div className='fv-plugins-message-container mb-5'>
                                <span className='text-danger ls-1'>{errors.gender.message}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 mb-5'>
                      <div className='row fv-row'>
                        <div className='col-sm-3'>
                          <label className='form-label'>
                            Is a health card required?<span className='required'></span>
                          </label>
                        </div>
                        <div className='col-sm-9'>
                          <div className='row fv-row'>
                            <div className='col-sm-2'>
                              <Radio
                                label='True'
                                type='radio'
                                value='true'
                                register={register(
                                  'is_health_card_required',
                                  CreatePositionsValidations.is_health_card_required
                                )}
                              />
                            </div>
                            <div className='col-sm-2'>
                              <Radio
                                label='False'
                                type='radio'
                                value='false'
                                register={register('is_health_card_required')}
                              />
                            </div>
                            {errors.is_health_card_required &&
                              errors.is_health_card_required.message && (
                                <div className='fv-plugins-message-container mb-5'>
                                  <span className='text-danger ls-1'>
                                    {errors.is_health_card_required.message}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row fv-row mb-5 border-bottom-dashed border-gray-200'>
                    <div className='col-sm-12'>
                      <div className='row fv-row'>
                        <div className='col-sm-3'>
                          <label className='form-label py-8'>
                            Hourly rates <span className='required'></span>
                          </label>
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Sun.</label>
                          <TextFieldGroup
                            type='text'
                            name='hourly_rates.sun'
                            register={register(
                              'hourly_rates.sun',
                              CreatePositionsValidations.hourly_rates.sun
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.sun &&
                              errors.hourly_rates.sun.message
                            }
                          />
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Mon.</label>
                          <TextFieldGroup
                            type='text'
                            name='hourly_rates.mon'
                            register={register(
                              'hourly_rates.mon',
                              CreatePositionsValidations.hourly_rates.mon
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.mon &&
                              errors.hourly_rates.mon.message
                            }
                          />
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Tue.</label>
                          <TextFieldGroup
                            type='text'
                            name='hourly_rates.tue'
                            register={register(
                              'hourly_rates.tue',
                              CreatePositionsValidations.hourly_rates.tue
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.tue &&
                              errors.hourly_rates.tue.message
                            }
                          />
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Wed.</label>
                          <TextFieldGroup
                            type='text'
                            name='hourly_rates.wed'
                            register={register(
                              'hourly_rates.wed',
                              CreatePositionsValidations.hourly_rates.wed
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.wed &&
                              errors.hourly_rates.wed.message
                            }
                          />
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Thu.</label>
                          <TextFieldGroup
                            type='text'
                            name='hourly_rates.thu'
                            register={register(
                              'hourly_rates.thu',
                              CreatePositionsValidations.hourly_rates.thu
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.thu &&
                              errors.hourly_rates.thu.message
                            }
                          />
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Fri</label>
                          <TextFieldGroup
                            type='text'
                            name='fri'
                            register={register(
                              'hourly_rates.fri',
                              CreatePositionsValidations.hourly_rates.fri
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.fri &&
                              errors.hourly_rates.fri.message
                            }
                          />
                        </div>
                        <div className='col-sm'>
                          <label className='form-label'>Sat.</label>
                          <TextFieldGroup
                            type='text'
                            name='sat'
                            register={register(
                              'hourly_rates.sat',
                              CreatePositionsValidations.hourly_rates.sat
                            )}
                            error={
                              errors.hourly_rates &&
                              errors.hourly_rates.sat &&
                              errors.hourly_rates.sat.message
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div className='row fv-row'>
                        <div className='col-sm-3'>
                          <label className='form-label py-2'>
                            AS3A % <span className='required'></span>
                          </label>
                        </div>
                        <div className='col-sm-4'>
                          <TextFieldGroup
                            type='text'
                            name='as3a_percentage'
                            register={register('as3a_percentage')}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 mb-5'>
                      <div className='row fv-row'>
                        <div className='col-sm-3'>
                          <label className='form-label'>Description</label>
                        </div>
                        <div className='col-sm-9'>
                          <textarea
                            placeholder='Enter Description'
                            className='form-control form-control-sm form-control-solid'
                            rows={3}
                            cols={50}
                            {...register('position_description')}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div className='row fv-row mb-10'>
                        <div className='col-sm-3'>
                          <label className='form-label'>Requirements description</label>
                        </div>
                        <div className='col-sm-9'>
                          <textarea
                            placeholder='Enter Requirements Description'
                            className='form-control form-control-sm form-control-solid'
                            rows={3}
                            cols={50}
                            {...register('position_requirements')}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row fv-row'>
                    <div className='col-sm-2'>
                      {params && params.position_uid && positionBranchCheck === false ? (
                        <button
                          className='btn btn-bg-primary btn-color-white fw-bolder'
                          onClick={handleSubmit(updateFn)}
                        >
                          Update
                          {loading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            ''
                          )}
                        </button>
                      ) : (
                        <button
                          className='btn btn-bg-primary btn-color-white fw-bolder'
                          onClick={handleSubmit(submit)}
                        >
                          Submit
                          {loading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            ''
                          )}
                        </button>
                      )}
                    </div>
                    <div className='col-sm'></div>
                  </div>
                </div>
                <div className='col-sm-2'>
                  <img
                    alt='present'
                    src={toAbsoluteUrl('/as3a-media/sketchy_pro_svg_v1.0/response_1.svg')}
                    style={{width: '210px', height: '210px', position: 'absolute', bottom: '0'}}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePosition
