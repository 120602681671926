import React, {FC} from 'react'

interface props {
  name?: string
  value?: string
  id?: string
  type?: string
  register?: any
  label?: string
  disabled?: boolean
  error?: string
  isValidate?: string
}

const Radio: FC<props> = ({
  name,
  value,
  id,
  type,
  label,
  disabled,
  error,
  isValidate,
  register,
}) => {
  return (
    <>
      <div className='form-check form-check-custom form-check-solid form-check-sm'>
        <input
          className='form-check-input'
          name={name}
          id={id}
          type={type}
          value={value}
          {...register}
        />
        <label className='form-check-label'>
          {label} {isValidate === 'true' ? <span className='required'>*</span> : ''}
        </label>
      </div>
      <div className='fv-plugins-message-container mb-5'>
        <span className='text-danger ls-1'>{error}</span>
      </div>
    </>
  )
}

export default Radio
