import React, {FC, useEffect, useState} from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {withRouter, useLocation, useHistory, useParams} from 'react-router-dom'
import {DefauktModel} from '../../../../../_metronic/partials/widgets'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import DataGridTable from '../../../../comomn/DataGridTable'
import {companies} from '../../../../redux/actions/companyAction'
import {RootState} from '../../../../redux/reducers'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {users} from '../../../../redux/actions/authAction'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const Positions: FC = () => {
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)
  const positionList: any = useSelector<RootState>(({companies}) => companies.positionList)

  const [positionFilter, setPositionFilter] = useState<string>('')

  const history = useHistory()
  const params: any = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (params.id) {
      dispatch(companies.positionsList(params.id))
    }

    if (success.success) {
      successMsg(success)
    }
  }, [success.success, params.id])

  const successMsg = (msg: any) => {
    if (msg.success === true) {
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm',
          cancelButton: 'btn btn-white',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // dispatch(companies.positionsList(params.id))
          dispatch(companies.clearPopup())
        }
      })
    } else if (msg.success === false) {
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // setPopup(true)
          // closeModalRef.current.click()
          // setLoading(false)
        }
      })
    }
  }

  const columns: any = [
    {field: 'name_en', header: 'POSITION'},
    {field: 'branch', header: 'BRANCH'},
    {field: 'city', header: 'CITY'},
    {field: 'status', header: 'STATUS'},
  ]

  if (permissions.length > 0 && permissions[0]['permissions']['companies'].edit_position === true) {
    columns.push({field: 'action', sortable: false, header: 'DETAILS'})
  }

  const modifyColumns = (rowData: any, col: any) => {
    switch (col.field) {
      case 'branch':
        return (
          <span className='text-dark fw-bolder mb-1 fs-6'>
            {rowData.branch && rowData.branch.name_en}
          </span>
        )
      case 'city':
        return (
          <span className='text-dark fw-bolder mb-1 fs-6'>
            {rowData.city && rowData.city.name_en}
          </span>
        )
      case 'status':
        return statusFn(rowData)

      case 'action':
        return editButton(rowData)

      default:
        return <span className='text-dark fw-bolder mb-1 fs-6'>{rowData[col.field]}</span>
    }
  }

  const statusFn = (row: any) => {
    switch (row.state) {
      case 'active':
        return (
          <div>
            <span className='badge badge-light-success fs-7 fw-bolder'>{row.state}</span>
          </div>
        )
      case 'inactive':
        return (
          <div>
            <span className='badge badge-light-danger fs-7 fw-bolder'>{row.state}</span>
          </div>
        )
    }
  }

  const editButton = (row: any) => {
    // MenuComponent.reinitialization()

    return (
      <div style={{width: '100%', textAlign: 'right'}}>
        <span
          className='btn btn-light btn-active-light-primary btn-sm me-5 p-0 w-25px'
          style={{backgroundColor: 'white'}}
        >
          <span className='svg-icon svg-icon-dark svg-icon-1px me-5' onClick={() => copyFn(row)}>
            <KTSVG path='/as3a-media/DuoTune_Pro/svg/fil024.svg' className='svg-icon-1' />
          </span>
        </span>
        <span
          className='btn btn-light btn-active-light-primary btn-sm p-3'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => MenuComponent.reinitialization()}
        >
          Edit
        </span>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <span
              className='menu-link px-3 link-primary fw-bolder'
              onClick={() => editPosition(row)}
            >
              Edit
            </span>
          </div>

          <div className='menu-item px-3'>
            <span className='menu-link px-3 text-danger' onClick={() => deletePositionFn(row)}>
              Delete
            </span>
          </div>
        </div>
      </div>
    )
  }

  const copyFn = (row: any) => {
    let redirectPath: string = `/companies/companyProfile/${params.id}/editPosition/${row.id}`
    history.push(redirectPath)
    dispatch(users.positionBranch(true))
    // dispatch(companies.branchPositioncheck(''))
  }

  const deletePositionFn = (row: any) => {
    MySwal.fire({
      html: `Are you sure you want to delete ${row.name_en} ?`,
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      padding: '2.25rem',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-danger btn-sm me-2',
        cancelButton: 'btn btn-white',
        icon: 'text-warning border-warning mb-5',
        container: 'py-7',
      },
      showClass: {
        popup: 'swal2-show',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show',
      },
      hideClass: {
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeletPosition(row)
      }
    })
  }

  const confirmDeletPosition = (row: any) => {
    dispatch(companies.delatePositions(row.id))
  }

  const editPosition = (row: any) => {
    let redirectPath: string = `/companies/companyProfile/${params.id}/editPosition/${row.id}`
    history.push(redirectPath)
    dispatch(companies.branchPositioncheck(''))
    dispatch(users.positionBranch(false))
    dispatch(users.branchPositionCreate(false))
  }

  const addPosition = () => {
    let redirectPath: string = `/companies/companyProfile/${params.id}/createPosition`
    history.push(redirectPath)
    dispatch(companies.branchPositioncheck(''))
    dispatch(users.branchPositionCreate(false))
  }
  const positionsFilter = () => {
    const filterModel: any = {}
    filterModel.name_en = {value: positionFilter}
    return filterModel
  }
  const positionSearchFn = (e: any) => {
    setPositionFilter(e.target.value)
  }
  return (
    <>
      <h1 className='d-flex align-items-center text-dark-300 fw-bolder fs-3'>Positions</h1>
      <div className='card card-xxl-stretch mb-5 mb-xxl-8 pt-5'>
        <div className='card-body py-3'>
          <div className='d-flex flex-stack mb-5'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='black'
                  ></rect>
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='black'
                  ></path>
                </svg>
              </span>
              <input
                type='text'
                data-kt-docs-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search Position'
                onChange={(e) => positionSearchFn(e)}
              />
            </div>

            <div className='d-flex justify-content-end' data-kt-docs-table-toolbar='base'>
              {permissions.length > 0 &&
              permissions[0]['permissions']['companies'].create_position === true ? (
                <button
                  type='button'
                  className='btn btn-sm btn-bg-primary btn-color-white fw-bolder'
                  onClick={addPosition}
                >
                  <span className='svg-icon svg-icon-white me-1 '>
                    <KTSVG
                      path='/as3a-media/DuoTune_Pro/svg/arr009.svg'
                      className='w-30px h-30px'
                    />
                  </span>
                  Add Positions
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='table-responsive'>
            <DataGridTable
              columns={columns}
              rows={positionList}
              filterModel={positionsFilter()}
              modifyColums={(rows, col) => modifyColumns(rows, col)}
            />
          </div>
        </div>
        {/* <CreatePosition /> */}
      </div>
    </>
  )
}

export default Positions
