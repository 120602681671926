/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {RootState} from '../../../../app/redux/reducers'

export function AsideMenuMain() {
  const intl = useIntl()
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)

  return (
    <>
      {permissions.length > 0 && permissions[0]['permissions']['dashboard'].view_growth === true ? (
        <AsideMenuItem
          to='/dashboard'
          icon='/as3a-media/svg/sidemenu/dashboard.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : (
        ''
      )}
      {/* <div className='separator mx-1 my-4'></div> */}

      {/* {permissions.length > 0 && permissions[0]['permissions']['operations'].view === true ? (
        <AsideMenuItem
          to='/operations'
          icon='/as3a-media/DuoTune_Pro/svg/gen002.svg'
          title='Operations'
          fontIcon='bi-layers'
        />
      ) : (
        ''
      )} */}

      {permissions.length > 0 && permissions[0]['permissions']['companies'].view === true ? (
        <AsideMenuItem
          to='/companies'
          icon='/as3a-media/DuoTune_Pro/svg/ecm008.svg'
          title='Companies'
          fontIcon='bi-layers'
        />
      ) : (
        ''
      )}

      {permissions.length > 0 && permissions[0]['permissions']['ambitious'].view === true ? (
        <AsideMenuItem
          to='/ambitious'
          icon='/as3a-media/DuoTune_Pro/svg/com006.svg'
          title='Ambitious'
          fontIcon='bi-layers'
        />
      ) : (
        ''
      )}
      {permissions.length > 0 && permissions[0]['permissions']['finance'].view === true ? (
        <AsideMenuItem
          to='/finance'
          icon='/as3a-media/DuoTune_Pro/svg/fin001.svg'
          title='Finance'
          fontIcon='bi-layers'
        />
      ) : (
        ''
      )}
      {permissions.length > 0 && permissions[0]['permissions']['users'].view === true ? (
        <AsideMenuItem
          to='/users'
          icon='/as3a-media/DuoTune_Pro/svg/com005.svg'
          title='Users'
          fontIcon='bi-layers'
        />
      ) : (
        ''
      )}

      <div className='separator mx-1 my-4'></div>
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
    </>
  )
}
