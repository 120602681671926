import {
    GET_ALL_COMPANIES_LIST,
    GET_CITI_LIST,
    GET_BRANCHES_LIST,
    COMPANY_PROFILE_LIST,
    EDIT_BRANCH_LIST,
    BRANCHES_POSITION_LIST,
    POSITIONS_LIST,
    EDIT_POSITION_LIST,
    GET_PHOTOS_LIST
} from '../actions/types'

const initialState = {
    companyList: [],
    companyprofile: {},
    cities: [],
    branchList: [],
    branchEdit: {},
    barnchPosition: [],
    positionList: [],
    positionEdit: {},
    photos: [],
}

const companiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ALL_COMPANIES_LIST: return { ...state, companyList: action.payload };
        case COMPANY_PROFILE_LIST: return { ...state, companyprofile: action.payload };
        case GET_CITI_LIST: return { ...state, cities: action.payload };
        case GET_BRANCHES_LIST: return { ...state, branchList: action.payload };
        case EDIT_BRANCH_LIST: return { ...state, branchEdit: action.payload };
        case BRANCHES_POSITION_LIST: return { ...state, barnchPosition: action.payload };
        case POSITIONS_LIST: return { ...state, positionList: action.payload };
        case EDIT_POSITION_LIST: return { ...state, positionEdit: action.payload };
        case GET_PHOTOS_LIST: return { ...state, photos: action.payload }
        default: return state;
    }
}

export default companiesReducer
