/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../app/redux/reducers'
import {Languages} from './Languages'

import {toAbsoluteUrl} from '../../../helpers'
import {users} from '../../../../app/redux/actions/authAction'

const HeaderUserMenu: FC = () => {
  // const user: any = useSelector<RootState>(({auth}) => auth.users, shallowEqual)
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)
  const dispatch = useDispatch()
  const signout = () => {
    dispatch(users.signOut())
    dispatch(users.permissions())
  }

  const userLogo = (name: any) => {
    return name.charAt(0).toUpperCase() + name.slice(0, 0)
  }

  const userLableName = (name: any) => {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-2'>
            {permissions.length > 0 && permissions[0].img_url ? (
              <img alt='Logo' src={toAbsoluteUrl('/media/avatars/150-26.jpg')} />
            ) : (
              <div className='symbol symbol-45px symbol-rounded position-relative'>
                <span className='symbol-label bg-light-success text-success fs-4 fw-bolder'>
                  {permissions.length > 0 && permissions[0].name_en
                    ? userLogo(permissions[0].name_en)
                    : ''}
                  {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-0 bg-success rounded-circle border border-1 border-white h-10px w-10px'></div> */}
                </span>
              </div>
            )}
          </div>

          <div className='d-flex flex-column token-anchor'>
            <div className='fw-bolder d-flex align-items-center fs-5 token-anchor'>
              {permissions.length > 0 && permissions[0].name_en
                ? userLableName(permissions[0].name_en)
                : ''}

              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7 token-anchor'>
              {permissions.length > 0 && permissions[0].email ? permissions[0].email : ''}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5' onClick={() => signout()}>
        <Link to='/auth' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
