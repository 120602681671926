import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import {firebaseReducer} from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore'
import authReducer from './authReducer'
import usersReducer from './usersReducer'
import companiesReducer from './companiesReducer'
import dashboardReducer from './dashboardReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  companies: companiesReducer,
  dashboard: dashboardReducer,
  // firebase: firebaseReducer,
  firestore: firestoreReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default persistReducer(persistConfig, rootReducer)
