import React, {FC, useState, useEffect, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Select, {StylesConfig} from 'react-select'
import {useLocation, useHistory, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useForm, Controller} from 'react-hook-form'
import TextFieldGroup from '../../../../comomn/TextFieldGroup'
import CheckBox from '../../../../comomn/CheckBox'
import {companies} from '../../../../redux/actions/companyAction'
import {RootState} from '../../../../redux/reducers'
import CreateBranchesValidations from '../../../../comomn/validations/companies/branches/createBranches'
import Gmap from '../../../../comomn/maps/Gmap'
import Autocomplete from 'react-google-autocomplete'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

type FormValues = {
  branch_address: string
  city: string
  // cityFile: string
  company_name: string
  company_uid: string
  latitude: number
  longitude: number
  location_description: string
  name_ar: string
  name_en: string
  manager: {
    name: string
    mobile: string
  }
}

const CreateBranch: FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: {errors},
    control,
    reset,
  } = useForm<FormValues>()
  const success: any = useSelector<RootState>(({auth}) => auth.success)
  const cities: any = useSelector<RootState>(({companies}) => companies.cities)
  const branchEdit: any = useSelector<RootState>(({companies}) => companies.branchEdit)

  // const [branchArr, setBranchArr] = useState<any>([])

  const [selectOption, setSelectOption] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [searchLocation, setSearchLocation] = useState<string>('')
  // const [citiList, setCityList] = useState<any>([])
  const [default_value, setDefaultValue] = useState<string>('')

  const history = useHistory()
  const params: any = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.branch_uid) {
      dispatch(companies.cities())
      dispatch(companies.editListBranch(params.id, params.branch_uid))
      if (branchEdit.name_en) {
        const values: any = Object.values(branchEdit)
        Object.keys(branchEdit).map((item: any, i) => setValue(item, values[i]))
        setSelectOption({
          value: branchEdit.city.id,
          label: branchEdit.city.name_en,
          ...branchEdit,
        })
      }
    } else {
      dispatch(companies.cities())
    }

    if (success.success === true) {
      setLoading(false)
      let redirectPath: string = `/companies/companyProfile/${params.id}/branches`
      history.push(redirectPath)
    } else if (success.success === false) {
      successMsg(success)
    }
  }, [success.success, params.branch_uid, branchEdit.name_en])

  const successMsg = (msg: any) => {
    if (msg.success === false) {
      dispatch(companies.clearPopup())
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }
  }

  const optionsFn = () => {
    const options: any = []
    cities.forEach((item: any, index: any) => {
      return (options[index] = {value: item.id, label: item.name, ...item})
    })

    return options.sort((a: any, b: any) => a.name.localeCompare(b.name))
  }

  const colourStyles: StylesConfig = {
    control: (styles) => ({...styles, backgroundColor: '#eef1f5', border: 'none'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? '#2596be'
          : isFocused
          ? '#2596be'
          : undefined,
        color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? '#fffff' : 'white') : undefined,
        },
      }
    },
  }

  const handelChange = (e: any) => {
    console.log(e)
    setSelectOption(e)
    setValue('city', e.value)
    setDefaultValue(e.value)
  }

  const submit = (data: any) => {
    data.city = {
      id: selectOption.id,
      name_en: selectOption.name,
      name_ar: selectOption.name_arabic,
    }
    if (data.latitude !== 23.885942 && data.longitude !== 45.079163) {
      setLoading(true)
      dispatch(companies.createBranch(params.id, data))
    } else {
      dispatch(companies.locationError())
    }
  }

  const update = (data: any) => {
    setLoading(true)
    dispatch(companies.updateBranch(params.id, params.branch_uid, data))
  }

  const mapDetails = (data: any) => {
    if (!params.branch_uid && data.address) {
      // setValue('location_description', data.address)
      setValue('latitude', data.mapPosition.lat)
      setValue('longitude', data.mapPosition.lng)
    } else {
      setValue('latitude', data.mapPosition.lat)
      setValue('longitude', data.mapPosition.lng)
    }
  }

  // let default_value: string = selectOption.value

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className='card card-xxl-stretch mb-5 mb-xxl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              {params && params.branch_uid ? (
                <span className='card-label fw-bolder fs-3 mb-1'>Edit Branch</span>
              ) : (
                <span className='card-label fw-bolder fs-3 mb-1'>Create Branch</span>
              )}
              {/* <DefauktModel /> */}
            </h3>
          </div>
          <div className='card-body py-3'>
            <form>
              <div className='row fv-row mb-5'>
                <div className='col-sm-10'>
                  <div className='row fv-row mb-10 pt-2 border-bottom-dashed border-gray-200'>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>
                        Name in Arabic<span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        type='text'
                        name='name_ar'
                        placeholder='Enter Branch Name in Arabic'
                        register={register('name_ar', CreateBranchesValidations.name_ar)}
                        disabled={params && params.branch_uid ? true : false}
                        error={errors.name_ar && errors.name_ar.message}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>
                        Name in English<span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        type='text'
                        name='name_en'
                        placeholder='Enter Branch Name in English'
                        register={register('name_en', CreateBranchesValidations.name_en)}
                        disabled={params && params.branch_uid ? true : false}
                        error={errors.name_en && errors.name_en.message}
                      />
                    </div>
                    <div className='col-sm-3 mb-5'>
                      <label className='form-label py-2'>
                        City <span className='required'></span>
                      </label>
                    </div>
                    <div className='col-sm-9 mb-5'>
                      <Controller
                        control={control}
                        // defaultValue={'text'}
                        name='city'
                        render={({onChange, value, name, ref}: any) => (
                          <Select
                            placeholder={'Select city'}
                            // name={name}
                            styles={colourStyles}
                            options={optionsFn()}
                            onChange={handelChange}
                            value={selectOption && selectOption.value ? selectOption : ''}
                            isDisabled={params && params.branch_uid ? true : false}
                          />
                        )}
                        rules={
                          !getValues('city') ? CreateBranchesValidations.city : {required: false}
                        }
                      />
                      {errors.city && errors.city.message && (
                        <div className='fv-plugins-message-container mb-5'>
                          <span className='text-danger ls-1'>{errors.city.message}</span>
                        </div>
                      )}
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>Location Description</label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        type='text'
                        name='location_description'
                        placeholder='Enter Location Description'
                        register={register('location_description')}
                        disabled={params && params.branch_uid ? true : false}
                      />
                    </div>
                    <div className='col-sm-3 mb-5'>
                      <label className='form-label'>
                        Location on Map <span className='required'></span>
                      </label>
                    </div>

                    <div className='col-sm-12'>
                      <div className='mb-5' style={{position: 'relative'}}>
                        <Gmap
                          height={'400px'}
                          mapDetails={(data: any) => mapDetails(data)}
                          editObj={
                            params.branch_uid && branchEdit
                              ? {lat: branchEdit.latitude, lng: branchEdit.longitude}
                              : {lat: 23.885942, lng: 45.079163}
                          }
                        />
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label py-2'>Manager Name</label>
                    </div>
                    <div className='col-sm-9'>
                      <TextFieldGroup
                        type='text'
                        name='manager.name'
                        placeholder='Enter Manager Name'
                        register={register('manager.name', CreateBranchesValidations.manager.name)}
                        error={errors.manager && errors.manager.name && errors.manager.name.message}
                      />
                    </div>
                    <div className='col-sm-3 mb-10'>
                      <label className='form-label py-2'>Manager Mobile Number</label>
                    </div>
                    <div className='col-sm-9 mb-10'>
                      <TextFieldGroup
                        type='text'
                        name='manager.mobile'
                        placeholder='Enter Manager Mobile Number'
                        register={register(
                          'manager.mobile',
                          CreateBranchesValidations.manager.mobile
                        )}
                        error={
                          errors.manager && errors.manager.mobile && errors.manager.mobile.message
                        }
                      />
                    </div>
                  </div>
                  <div className='row fv-row'>
                    <div className='col-sm-2'>
                      {params && params.branch_uid ? (
                        <button
                          className='btn btn-bg-primary btn-color-white'
                          type='button'
                          onClick={handleSubmit(update)}
                        >
                          Update
                          {loading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            ''
                          )}
                        </button>
                      ) : (
                        <button
                          className='btn btn-bg-primary btn-color-white'
                          type='button'
                          onClick={handleSubmit(submit)}
                        >
                          Submit
                          {loading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            ''
                          )}
                        </button>
                      )}
                    </div>
                    <div className='col-sm'></div>
                  </div>
                </div>
                <div className='col-sm-2 mb-10'>
                  <img
                    alt='present'
                    src={toAbsoluteUrl('/as3a-media/sketchy_pro_svg_v1.0/delivery_1.svg')}
                    style={{width: '210px', height: '210px', position: 'absolute', bottom: '0'}}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateBranch
