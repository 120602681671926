const createCompanyValidations = {
    name_en: {
        required: {
            value: true,
            message: "name is required",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid name",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
    },
    name_ar: {
        required: {
            value: true,
            message: "name is required",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
        // pattern: {
        //     value: /^[A-Za-z\s]+$/,
        //     message: "Please enter valid name",
        // },

    },
    cr_number: {
        required: {
            value: true,
            message: "Certificate registration number is required",
        },
        pattern: {
            value: /^[0-9]+$/,
            message: "Please enter valid certificate registration",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 digits",
        }


    },
    employer_labor_office_id: {
        required: {
            value: true,
            message: "Labor Id is required",
        },
        pattern: {
            value: /^[0-9]+$/,
            message: "Please enter valid labour ID",
        },

    },
    employer_sequence_number: {
        required: {
            value: true,
            message: "Labor sequence number is required",
        },
        pattern: {
            value: /^[0-9]+$/,
            message: "Please enter valid labour sequence number",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 digits",
        }

    },

    vat_no: {
        required: {
            value: true,
            message: "VAT number is required",
        },
        pattern: {
            value: /^[0-9]+$/,
            message: "Please enter valid VAT number",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 digits",
        }

    },
}

export default createCompanyValidations;