import {initializeApp, getApp} from 'firebase/app'
import {getFirestore} from '@firebase/firestore'
import {getFunctions, connectFunctionsEmulator} from '@firebase/functions'
import '@firebase/auth'
import '@firebase/storage'
// import 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEIRL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGEINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

const app = initializeApp(firebaseConfig)

const firebase = getFirestore(app)

const functions = getFunctions(app)
// connectFunctionsEmulator(functions, 'localhost', 5001)
// console.log(functions)

export {firebase, functions}
