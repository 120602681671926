import clsx from 'clsx'
import {userInfo} from 'os'
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'

import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../app/redux/reducers'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()

  // const user: any = useSelector<RootState>(({auth}) => auth.users, shallowEqual)
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)

  const userLogo = (name: any) => {
    return name.charAt(0).toUpperCase() + name.slice(0, 0)
  }

  const userLableName = (name: any) => {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='fs-6 fw-bolder me-3'>
            <span className='text-secondary'>Hi, </span>
            {permissions.length > 0 && permissions[0].name_en
              ? userLableName(permissions[0].name_en)
              : ''}
          </span>
          {permissions.length > 0 && permissions[0].img_url ? (
            <img src={toAbsoluteUrl('/media/avatars/150-26.jpg')} alt='metronic' />
          ) : (
            <div className='symbol symbol-45px symbol-rounded position-relative'>
              <span className='symbol-label bg-light-success text-success fs-4 fw-bolder'>
                {permissions.length > 0 ? userLogo(permissions[0].name_en) : ''}
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-0 bg-success rounded-circle border border-1 border-white h-10px w-10px'></div> */}
              </span>
            </div>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div
          className='d-flex align-items-center d-lg-none ms-2 me-n3'
          title='Show header menu'
        ></div>
      )}
    </div>
  )
}

export {Topbar}
