/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Signin from './components/Signin'
import ForgetPassword from './components/ForgetPassword'

const AuthPage = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='container-fluid' style={{width: '98.5%', padding: '0px'}}>
          <div className='row fv-row'>
            <div
              className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4'
              style={{backgroundColor: '#0485B0'}}
            >
              <div className='row fv-row pt-20'>
                <div className='col-sm-12 pt-10 text-center'>
                  <div className='w-100 h-100 py-20 pt-10 px-2'>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/as3a-media/As3a Logo EN-AR png-2.png')}
                      style={{width: '136px', height: '136px'}}
                      // className='w-136px h-136px'
                    />

                    <h1
                      className='text-white mb-3'
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        fontSize: '26px',
                        lineHeight: '36px',
                      }}
                    >
                      Intelligent Human Energy
                    </h1>
                    <h1
                      className='text-white'
                      style={{
                        fontFamily: 'Cairo',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        fontSize: '26px',
                        lineHeight: '36px',
                      }}
                    >
                      ﺔﻴﻛذ ﺔﻳﺮﺸﺑ ﺔﻗﺎﻃ
                    </h1>
                  </div>
                </div>
              </div>

              <div className='col-sm-12 text-center'>
                <div className='w-100 h-100 px-5 pt-15 py-15'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/as3a-media/sketchy_pro_svg_v1.0/dialogue.svg')}
                    // style={{width: '100%', height: '700px'}}
                    className='w-100 h-100'
                  />
                </div>
              </div>
            </div>
            <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8'>
              <div className='d-flex flex-right flex-column flex-column-fluid p-10 pb-lg-20 pt-20'>
                <div
                  className='bg-white rounded  p-10 p-lg-30 mx-auto pt-20'
                  // style={{marginTop: '88px'}}
                >
                  <Switch>
                    <Route path='/auth/signin' component={Signin} />
                    <Route path='/auth/forgot-password' component={ForgetPassword} />
                    <Redirect from='/auth' exact={true} to='/auth/signin' />
                    <Redirect to='/auth/signin' />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthPage
