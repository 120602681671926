import React, {FC, Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../redux/reducers'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {FallbackView} from '../../_metronic/partials'
import Dashboard from '../components/dashboard/Dashboard'
import Users from '../components/users/Users'
import Companies from '../components/companies/Companies'

const PrivateRoutes: FC = () => {
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard'>
          <PageTitle>Dashboard</PageTitle>
          <Dashboard />
        </Route>
        <Route path='/companies'>
          {permissions.length > 0 && permissions[0]['permissions']['companies'].view === true ? (
            <>
              <PageTitle>Companies</PageTitle>
              <Companies />
            </>
          ) : (
            <Redirect from='/users' to='/' />
          )}
        </Route>
        <Route path='/users'>
          {permissions.length > 0 && permissions[0]['permissions']['users'].view === true ? (
            <>
              <PageTitle>Users</PageTitle>
              <Users />
            </>
          ) : (
            <Redirect from='/users' to='/' />
          )}
        </Route>
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect from='*' to='/' />
      </Switch>
    </Suspense>
  )
}

export default PrivateRoutes
