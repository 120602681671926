import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist'
import { getFirebase, ReactReduxFirebaseConfig } from 'react-redux-firebase';
import { getFirestore, createFirestoreInstance, reduxFirestore } from 'redux-firestore'

import rootReducer from './reducers';
import { firebase } from '../config/fbConfig';



const store = createStore(rootReducer, compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    // reduxFirestore(firebase),
));

const persistor = persistStore(store);


const rrrProps = {
    firebase,
    config: {
        userProfile: 'all_users',
        useFirestoreForProfile: true
    },
    dispatch: store.dispatch,
    createFirestoreInstance: createFirestoreInstance
}

export { store, persistor, rrrProps }
