/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import TextFieldGroup from '../../../comomn/TextFieldGroup'
import {users} from '../../../redux/actions/authAction'
import {RootState} from '../../../redux/reducers/index'
import isEmpty from '../../../comomn/utils/isEmpty'
import SigninValidation from '../../../comomn/validations/SigninValidation'

interface props {}

type FormValues = {
  email: string
  password: string
}

const Signin: FC<props> = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<FormValues>()

  const [loading, setLoading] = useState(false)

  const auth: any = useSelector<RootState>((state) => state.auth)

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(false)
    const {error} = auth
    if (error.error) {
      setLoading(false)
    }

    if (errors.email || errors.password) {
      console.log(errors)
      dispatch(users.refreshErr())
    }

    if (!loading) {
      dispatch(users.refreshErr())
    }
  }, [auth.error.error, errors.email, errors.password])

  const submitUser = (data: any) => {
    setLoading(true)
    dispatch(users.signin(data))
  }

  return (
    <form className='form w-100 pt-20' noValidate>
      <div className=' mb-20'>
        <h1 className='text-dark-700 mb-2' style={{fontSize: '30px', fontFamily: 'Poppins'}}>
          Welcome to AS3A.
        </h1>
        <div className='text-gray-500 fw-bold ' style={{fontSize: '18px'}}>
          Please enter your email and password
        </div>
        {auth.error && auth.error.error ? (
          <>
            {auth.error.error === 'invalid-email' ? (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Please enter correct email Id</div>
              </div>
            ) : auth.error.error === 'wrong-password' ? (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Please enter correct password</div>
              </div>
            ) : (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Invalid credential</div>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>

      <div className='fv-row mb-10'>
        <TextFieldGroup
          label='Email'
          type='email'
          name='email'
          register={register('email', SigninValidation.email)}
          placeholder='Email'
          error={errors.email && errors.email.message}
        />
      </div>

      <div className='fv-row mb-20'>
        <TextFieldGroup
          label='Password'
          type='password'
          name='password'
          register={register('password', SigninValidation.password)}
          placeholder='Password'
          autoComplete='off'
          error={errors.password && errors.password.message}
        />
        <Link
          to='/auth/forgot-password'
          className='link-primary fs-6 fw-bolder'
          style={{marginLeft: '5px', float: 'right'}}
        >
          Forgot Password ?
        </Link>
      </div>

      <div className='text-center pb-15'>
        <button
          type='submit'
          className='btn btn-lg btn-bg-primary btn-color-white w-50 mb-5'
          onClick={handleSubmit(submitUser)}
        >
          <span className='indicator-label'>
            Sign In
            {loading && auth.error && !auth.error.error ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              ''
            )}
          </span>
        </button>
      </div>
    </form>
  )
}

export default withRouter(Signin)
