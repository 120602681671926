const CreatePositionsValidations = {
    branches_ids: {
        required: {
            value: true,
            message: "requires at least one branch selected",
        },
    },
    name_ar: {
        required: {
            value: true,
            message: "name is required",
        },
        // pattern: {
        //     value: /^[A-Za-z\s]+$/,
        //     message: "Please enter valid name",
        // },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
    },
    name_en: {
        required: {
            value: true,
            message: "name is required",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid name",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
    },
    gosi_title: {
        required: {
            value: true,
            message: "Gosi title is required",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid gosi title",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
    },
    gosi_id: {
        required: {
            value: true,
            message: "GOSI ID is required",
        },
        pattern: {
            value: /^[0-9\s]+$/,
            message: "Please enter only digits",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 digits",
        }
    },
    age_from: {
        required: {
            value: true,
            message: "Age is required",
        },
        pattern: {
            value: /^[0-9\s]+$/,
            message: "Please enter only digits",
        },
        min: {
            value: 18,
            message: "please enter min age of 18"
        },
    },
    gender: {
        required: {
            value: true,
            message: "Gender is required",
        },
    },
    is_health_card_required: {
        required: {
            value: true,
            message: "Health card is required.",
        },
    },
    hourly_rates: {
        sun: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        },
        mon: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        },
        tue: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        },
        wed: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        },
        thu: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        },
        fri: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        },
        sat: {
            required: {
                value: true,
                message: "Hourly rate required",
            },
            pattern: {
                value: /^[0-9\s]+$/,
                message: "Please enter only digits",
            },
            min: {
                value: 25,
                message: "Please enter range from 25.0 to 300.0"
            },
            max: {
                value: 300,
                message: "Please enter range from 25.0 to 300.0"
            }
        }
    },
    position_description: {
        required: {
            value: true,
            message: "Description is required.",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter valid description",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
    },
    position_requirements: {
        required: {
            value: true,
            message: "Requirements is required.",
        },
        pattern: {
            value: /^[A-Za-z\s]+$/,
            message: "Please enter only requirements",
        },
        minLength: {
            value: 2,
            message: "Please enter at least 2 characters",
        }
    }

}

export default CreatePositionsValidations;