import React, {FC, useEffect, useState, createRef} from 'react'
import {shallowEqual, useSelector, connect, useDispatch} from 'react-redux'
import moment from 'moment'
import {userList} from '../../redux/actions/usersAction'
import {RootState} from '../../redux/reducers'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import DataGridTable from '../../comomn/DataGridTable'
import {DefauktModel} from '../../../_metronic/partials/widgets'
import {UserModel} from './components/UserModel'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {Timestamp} from 'firebase/firestore'

import {MenuComponent} from '../../../_metronic/assets/ts/components'

const MySwal = withReactContent(Swal)

const Users: FC = () => {
  const userData: any = useSelector<RootState>(({users}) => users.users)
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)
  const success: any = useSelector<RootState>(({auth}) => auth.success)

  const [isActive, setIsActive] = useState('')
  const [userObj, setUserObj] = useState({})
  const [userArr, setUserArr] = useState([])
  const [isPopup, setPopup] = useState(false)
  const [userFilter, setUserFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const closeModalRef: any = createRef()

  const dispatch = useDispatch()

  useEffect(() => {
    setPopup(false)
    if (userData.length > 0) {
      setUserArr(userData)
    } else {
      dispatch(userList.allUsers())
    }
    if (success.success) {
      successMsg(success)
    }
  }, [userData, success.success])

  const successMsg = (msg: any) => {
    if (msg.success === true) {
      closeModalRef.current.click()
      MySwal.fire({
        html: `${msg.message}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm',
          cancelButton: 'btn btn-white',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          closeModalRef.current.click()
          setLoading(false)
          setPopup(true)
          dispatch(userList.allUsers())
        }
      })
    } else if (msg.success === false) {
      MySwal.fire({
        html: `${msg.error && msg.error.errorInfo ? msg.error.errorInfo.message : msg.error}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        padding: '2.25rem',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-bg-primary btn-color-white btn-sm me-2',
          container: 'py-7',
        },
        showClass: {
          popup: 'swal2-show',
          backdrop: 'swal2-backdrop-show',
          icon: 'swal2-icon-show',
        },
        hideClass: {
          popup: 'swal2-hide',
          backdrop: 'swal2-backdrop-hide',
          icon: 'swal2-icon-hide',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // setPopup(true)
          // closeModalRef.current.click()
          setLoading(false)
        }
      })
    }
  }

  const columns: any = [
    {field: 'name_en', sortable: false, header: 'User'},
    {field: 'email', sortable: false, header: 'Email'},
    {field: 'create_time', sortable: true, header: 'Join Date'},
  ]

  if (permissions.length > 0 && permissions[0]['permissions']['users'].manage_users === true) {
    columns.push({
      field: 'action',
      sortable: false,
      header: 'Details',
    })
  }

  const editButton = (rowData: any) => {
    // MenuComponent.reinitialization()
    return (
      <>
        <div style={{width: '100%', textAlign: 'right'}}>
          <span
            className='btn btn-light btn-active-light-primary btn-sm p-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={() => MenuComponent.reinitialization()}
          >
            Edit
          </span>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
            data-kt-menu='true'
          >
            <div className='menu-item px-2'>
              <a
                href='#kt_modal_create_app1'
                data-bs-toggle='modal'
                onClick={() => edituser(rowData)}
                className='menu-link px-6 link-primary'
              >
                Edit
              </a>
            </div>

            <div className='menu-item px-2'>
              <span className='menu-link px-6 text-danger' onClick={() => deleteUser(rowData)}>
                Delete
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }

  // const testEditFn = () => {
  //   MenuComponent.reinitialization()
  //   console.log('this is working fine')
  // }

  const edituser = (rowdata: any) => {
    setIsActive('edit')
    setUserObj(rowdata)
  }

  const deleteUser = (row: any) => {
    MySwal.fire({
      html: `Are you sure you want to delete ${row.name_en} ?`,
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel',
      padding: '2.25rem',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-danger btn-sm me-2',
        cancelButton: 'btn btn-white',
        icon: 'text-warning border-warning mb-5',
        container: 'py-7',
      },
      showClass: {
        popup: 'swal2-show',
        backdrop: 'swal2-backdrop-show',
        icon: 'swal2-icon-show',
      },
      hideClass: {
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDeletUser(row)
      }
    })
  }

  const confirmDeletUser = (user: any) => {
    dispatch(userList.userbyDelete(user))
  }

  const modifyColumns = (rowData: any, col: any) => {
    switch (col.field) {
      case 'name_en':
        return (
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-35px me-5'>
              {rowData.imgUrl ? (
                <span className='symbol-label bg-light symbol-fixed position-relative'>
                  <img
                    src={toAbsoluteUrl(`media/avatars/${rowData.imgUrl}`)}
                    className='h-35px align-self-end rounded-circle'
                    alt=''
                  />
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-0 bg-success rounded-circle border border-1 border-white h-10px w-10px'></div>
                </span>
              ) : (
                <div className='symbol symbol-35px symbol-circle position-relative'>
                  <span className='symbol-label bg-light-warning text-warning fs-6 fw-bolder'>
                    {userLableName(rowData.name_en)}
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-0 bg-success rounded-circle border border-1 border-white h-10px w-10px'></div>
                  </span>
                </div>
              )}
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span className='text-dark fw-bolder mb-1 fs-6'>{rowData.name_en}</span>
            </div>
          </div>
        )
      case 'create_time':
        return convertTime(rowData.create_time)
      case 'action':
        return editButton(rowData)

      default:
        return <span className='text-dark fw-bolder mb-1 fs-6'>{rowData[col.field]}</span>
    }
  }

  const convertTime = (date: any) => {
    let newDate: any = new Timestamp(date.seconds, date.nanoseconds).toDate()
    let create_time: string = moment(newDate).format('ll')
    return <span className='text-dark fw-bolder  mb-1 fs-6'>{create_time}</span>
  }

  const userLableName = (name_en: any) => {
    return name_en.charAt(0).toUpperCase() + name_en.slice(0, 0)
  }

  const addUsers = () => {
    setIsActive('add')
  }

  const usersFilter = () => {
    const filterModel: any = {}
    filterModel.name_en = {value: userFilter}
    return filterModel
  }

  const usersearchFn = (e: any) => {
    setUserFilter(e.target.value)
  }

  return (
    <div className='row gy-5 g-xl-8'>
      <div className='card card-xxl-stretch mb-5 mb-xxl-8 pt-5'>
        <DefauktModel />
        <div className='card-body py-3'>
          <div className='d-flex flex-stack mb-5'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='black'
                  ></rect>
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='black'
                  ></path>
                </svg>
              </span>
              <input
                type='text'
                data-kt-docs-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search User'
                onChange={(e) => usersearchFn(e)}
              />
            </div>

            <div className='d-flex justify-content-end' data-kt-docs-table-toolbar='base'>
              {permissions.length > 0 &&
              permissions[0]['permissions']['users'].manage_users === true ? (
                <button
                  type='button'
                  className='btn btn-sm btn-bg-primary btn-color-white fw-bolder'
                  onClick={addUsers}
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_app1'
                >
                  <span className='svg-icon svg-icon-white me-1 '>
                    <KTSVG
                      path='/as3a-media/DuoTune_Pro/svg/arr009.svg'
                      className='w-30px h-30px'
                    />
                  </span>
                  Add User
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='table-responsive user_table'>
            <DataGridTable
              columns={columns}
              rows={userArr}
              filterModel={usersFilter()}
              modifyColums={(rows, col) => modifyColumns(rows, col)}
            />
          </div>
        </div>
        <UserModel
          isActive={isActive}
          userObj={userObj}
          isPopup={isPopup}
          loading={loading}
          setLoading={setLoading}
          closeModalRef={closeModalRef}
        />
      </div>
    </div>
  )
}

export default Users
