import React, {FC, useState, useEffect, createRef} from 'react'
import {
  Link,
  withRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {DefauktModel} from '../../../../../_metronic/partials/widgets'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'

import {RootState} from '../../../../redux/reducers'

import {companies} from '../../../../redux/actions/companyAction'

import Branches from '../branches/Branches'
import CreateBranch from '../branches/CreateBranch'
import EditBranch from '../branches/EditBranch'
import CreatePosition from '../positions/CreatePosition'
import EditCompanyProfile from './EditCompanyProfile'
import Positions from '../positions/Positions'
import Photos from '../photps/Photos'

const CompanyProfile: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const params: any = useParams()

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Companies',
      path: '/companies',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'companyProfile',
      path: `/companies/companyProfile/${params.id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const pageLoader: any = useSelector<RootState>(({auth}) => auth.pageLoader)
  const permissions: any = useSelector<RootState>(({auth}) => auth.permissions, shallowEqual)
  const profile: any = useSelector<RootState>(({companies}) => companies.companyprofile)

  const [profileObj, setProfileObj] = useState<any>({})
  const [isCheckLogo, setIscheckLogo] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (params.id || isCheckLogo === true) {
      dispatch(companies.companyProfileList(params.id))
      setIscheckLogo(false)
    }
  }, [params.id, isCheckLogo])

  const editCompany = (obj: any) => {
    setProfileObj(obj)
  }

  return (
    <>
      {location.pathname === `/companies/companyProfile/${params.id}` ||
      location.pathname === `/companies/companyProfile/${params.id}/branches` ||
      location.pathname === `/companies/companyProfile/${params.id}/positions` ||
      location.pathname === `/companies/companyProfile/${params.id}/photos` ? (
        <>
          {/* {profile && profile.company_name ? ( */}
          <div className='card mb-5 mb-xl-10'>
            {pageLoader === true ? (
              <div className='splash-screen rounded'>
                <span className='spinner-border spinner-border-lg text-primary align-middle'></span>
                <span>Loading ...</span>
              </div>
            ) : (
              ''
            )}

            <div className='card-body pt-9 pb-0'>
              <div
                className='d-flex flex-wrap flex-sm-nowrap mb-3'
                style={{borderBottom: '1px dashed #eff2f5'}}
              >
                <div className='me-7 mb-4'>
                  <div className='w-200px h-150px py-1 position-relative'>
                    {profile && profile.company_logo_url !== null ? (
                      <img
                        src={toAbsoluteUrl(`${profile.company_logo_url}`)}
                        alt=''
                        className='w-200px h-150px rounded'
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl(`/media/avatars/blank.png`)}
                        alt=''
                        className='w-200px h-150px rounded'
                      />
                    )}
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column '>
                      <div className='d-flex align-items-center mb-2 '>
                        {profile && profile.company_name ? (
                          <h4
                            className={`text-gray-800 text-hover-primary fs-2 fw-bolder me-1 ${
                              profile.company_name.length > 30 ? `token-anchor w-250px` : ''
                            } `}
                            data-bs-toggle='tooltip'
                            data-bs-placement='bottom'
                            title={`${profile.company_name}`}
                          >
                            {profile.company_name}
                          </h4>
                        ) : (
                          ''
                        )}
                        <span>
                          <KTSVG
                            path='/media/icons/duotune/general/gen026.svg'
                            className='svg-icon-1 svg-icon-primary'
                          />
                        </span>
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        {profile && profile.active === true ? (
                          <span className='badge badge-light-success fs-7 fw-bolder mb-2'>
                            Active
                          </span>
                        ) : profile && profile.active === false ? (
                          <span className='badge badge-light-danger fs-7 fw-bolder mb-2'>
                            Inactive
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className='d-flex my-4'>
                      {permissions.length > 0 &&
                      permissions[0]['permissions']['companies'].edit_company === true ? (
                        <button
                          type='button'
                          className='btn btn-sm  btn-bg-primary btn-color-white fw-bolder'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_company_profile_Edit'
                          onClick={() => editCompany(profile)}
                        >
                          Edit Details
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                              <span className='fw-bold fs-6 text-gray-400'>Rating</span>
                              {profile && profile.no_rating ? (
                                <span className='fw-bolder fs-6'>
                                  {(profile.total_rating / profile.no_rating).toFixed(1)}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='h-5px mx-3 w-100 bg-light mb-3'>
                              {profile && profile.total_rating ? (
                                <div
                                  className='bg-warning rounded h-5px'
                                  role='progressbar'
                                  // style={{width: '80%'}}
                                  style={{
                                    width: `${(profile.total_rating / profile.no_rating) * 20}%`,
                                  }}
                                ></div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link me-6 ` +
                        (location.pathname === `/companies/companyProfile/${params.id}/branches` &&
                          'active text-active-primary')
                      }
                      to={`/companies/companyProfile/${params.id}/branches`}
                    >
                      Branches
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link me-6 ` +
                        (location.pathname === `/companies/companyProfile/${params.id}/positions` &&
                          'active text-active-primary')
                      }
                      to={`/companies/companyProfile/${params.id}/positions`}
                    >
                      Positions
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link me-6 ` +
                        (location.pathname === `/companies/companyProfile/${params.id}/photos` &&
                          'active text-active-primary')
                      }
                      to={`/companies/companyProfile/${params.id}/photos`}
                    >
                      Photos
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <EditCompanyProfile profileObj={profileObj} setIscheckLogo={setIscheckLogo} />
          </div>
        </>
      ) : (
        ''
      )}
      <Switch>
        <Route path='/companies/companyProfile/:id/branches'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Branches</PageTitle>
          <Branches />
        </Route>
        <Route path='/companies/companyProfile/:id/editBranch/:branch_uid'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Edit Branch</PageTitle>
          <CreateBranch />
        </Route>
        <Route path='/companies/companyProfile/:id/createBranch'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Create Branch</PageTitle>
          <CreateBranch />
        </Route>
        <Route path='/companies/companyProfile/:id/branch/:branch_uid'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Branch Position</PageTitle>
          <EditBranch />
        </Route>
        <Route path='/companies/companyProfile/:id/positions'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Positions</PageTitle>
          <Positions />
        </Route>
        <Route path='/companies/companyProfile/:id/createPosition'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Create Positions</PageTitle>
          <CreatePosition />
        </Route>
        <Route path='/companies/companyProfile/:id/editPosition/:position_uid'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Edit Position</PageTitle>
          <CreatePosition />
        </Route>
        <Route path='/companies/companyProfile/:id/photos'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Photos</PageTitle>
          <Photos />
        </Route>
        <Redirect
          from={`/companies/companyProfile/:id`}
          exact={true}
          to={`/companies/companyProfile/:id/branches`}
        />
        <Redirect to={`/companies/companyProfile/${params.id}/branches`} />
      </Switch>
    </>
  )
}

export default CompanyProfile
