import React, {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'

import {useForm} from 'react-hook-form'
import TextFieldGroup from '../../../comomn/TextFieldGroup'
import SigninValidation from '../../../comomn/validations/SigninValidation'
import {users} from '../../../redux/actions/authAction'
import {RootState} from '../../../redux/reducers'

type FormValues = {
  email: string
}

const ForgetPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<FormValues>()
  const dispatch = useDispatch()

  const auth: any = useSelector<RootState>((state) => state.auth)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
    const {error} = auth
    if (error.error) {
      setLoading(false)
    }
    if (auth.resrtPassword) {
      setLoading(false)
    }
    if (errors.email) {
      dispatch(users.refreshErr())
    }

    if (!loading) {
      dispatch(users.refreshErr())
    }
  }, [auth.error.error, auth.resrtPassword, errors.email])

  const submit = (data: any) => {
    setLoading(true)
    dispatch(users.forgetPassword(data))
  }

  return (
    <form className='form w-100 '>
      <div className=' mb-20'>
        <h1 className='text-dark mb-2'>Forgot Password ?</h1>
        <div className='text-gray-500 fw-bold' style={{fontSize: '18px'}}>
          Enter your email to reset your password.
        </div>
        {auth.error && auth.error.error ? (
          <>
            {auth.error.error === 'invalid-email' ? (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Please enter correct email Id</div>
              </div>
            ) : auth.error.error === 'user-not-found' ? (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Please enter registered email Id</div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {auth.resrtPassword ? (
          <div className='mb-lg-10 alert alert-info'>
            <div className='alert-text font-weight-bold'>
              Email has been sent to you, please verify.
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='fv-row mb-15'>
        <TextFieldGroup
          label='Email'
          type='email'
          name='email'
          register={register('email', SigninValidation.email)}
          placeholder='Email'
          error={errors.email && errors.email.message}
        />
      </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='button'
          className='btn btn-lg btn-bg-primary btn-color-white fw-bolder me-4'
          onClick={handleSubmit(submit)}
          disabled={auth.resrtPassword ? true : false}
        >
          {/* <span className='indicator-label'>Submit</span> */}
          <span className='indicator-label'>
            Submit
            {loading && auth.error && !auth.error.error ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              ''
            )}
          </span>
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            // className='btn btn-lg btn-bg-secondary fw-bolder'
            className='btn btn-light me-5 fw-bolder'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default ForgetPassword
