import React, {FC} from 'react'

interface props {
  name?: string
  placeholder?: string
  id?: string
  value?: string
  label?: string
  error?: string
  type?: string
  // onChange: (val: any) => void,
  register?: any
  disabled?: boolean
  maxlength?: number
  isValidate?: string
  autoComplete?: string
}

const TextFieldGroup: FC<props> = ({
  name,
  placeholder,
  id,
  value,
  label,
  error,
  type,
  // onChange,
  register,
  isValidate,
  maxlength,
  autoComplete,
  disabled,
}) => {
  return (
    <div>
      {label ? (
        <label className='form-label fs-6 fw-bolder text-dark'>
          {label}
          {isValidate === 'true' ? <span className='required'></span> : ''}
        </label>
      ) : (
        ''
      )}

      <input
        type={type}
        id={id}
        className='form-control form-control-sm form-control-solid'
        placeholder={placeholder}
        name={name}
        {...register}
        // value={value}
        defaultValue={value}
        disabled={disabled}
        autoComplete={autoComplete}
      />

      <div className='fv-plugins-message-container mb-5'>
        <span className='text-danger ls-1'>{error}</span>
      </div>
    </div>
  )
}

export default TextFieldGroup
