import React, {FC} from 'react'

interface Props {
  name?: string
  value?: string
  defaultChecked?: boolean
  id?: string
  type?: string
  label?: string
  // onchange?: (val: any) => void
  disabled?: boolean
  register?: any
  error?: string
  isValidate?: string
}

const CheckBox: FC<Props> = ({
  name,
  value,
  defaultChecked,
  id,
  type,
  label,
  // onchange,
  register,
  disabled,
  error,
  isValidate,
}) => {
  return (
    <>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type={type}
          name={name}
          id={id}
          {...register}
          // onChange={onchange}
          value={value}
          // defaultValue={value}
          defaultChecked={defaultChecked}
        />
        <label className='form-check-label'>
          {label}
          {isValidate === 'true' ? <span className='required'>*</span> : ''}
        </label>
      </div>
    </>
  )
}

export default CheckBox
