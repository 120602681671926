/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const Ambitious: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='row fv-row'>
        <div className='col-sm-8'>
          <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
            {/* begin::Hidden */}
            <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
              <div className='me-2'>
                <span className='fw-bolder text-gray-800 d-block fs-3'>Ambitious</span>

                <span className='text-gray-400 fw-bold'>More than 200+ new members</span>
              </div>

              <div className={`fw-bolder fs-3 text-${chartColor}`}>
                <button className='btn btn-sm  btn-bg-white'>Month</button>
                <button className='btn btn-sm btn-bg-white' style={{margin: '5px'}}>
                  Week
                </button>
                <button
                  className='btn btn-sm btn-color-white'
                  style={{backgroundColor: '#464E5F', borderRadius: '12px'}}
                >
                  Day
                </button>
              </div>
            </div>
            {/* end::Hidden */}

            {/* begin::Chart */}
            <div ref={chartRef} className='mixed-widget-10-chart'></div>
            {/* end::Chart */}
          </div>
        </div>
        <div className='col-sm-4 d-flex flex-wrap flex-grow-1 px-10 pt-9 pb-3'>
          <div
            className='d-flex  rounded px-10 mb-10 h-410px w-100'
            style={{backgroundColor: '#E9F5F9'}}
          >
            <div className='flex-grow-1 me-2 text-center pt-9 pb-5'>
              <h2 className='fw-bolder text-gray-800 text-hover-primary fs-1 pt-7'>67,005</h2>
              <span
                className='text-primary-800 fw-bold d-block fs-3 pt-5'
                style={{color: '#2596BE'}}
              >
                Number Of Ambitious
              </span>
              <img
                alt='present'
                src={toAbsoluteUrl('/as3a-media/sketchy_pro_svg_v1.0/community_1.svg')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  // const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Number Of Ambitious',
        data: [80, 99, 70, 80, 55, 90, 88, 100],
      },
      //   {
      //     name: 'Revenue',
      //     data: [50, 60, 70, 80, 60, 50, 70, 60],
      //   },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' revenue'
        },
      },
    },
    colors: [chartColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {Ambitious}
