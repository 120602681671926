import { GET_USER_LIST, RESET_PASSWORD, GET_USER_PERMISSIONS, API_SUCCESS, API_ERROR, POSITION_BRANCH_CHECK, BRANCH_POSITION_CREATE_CHECK } from './types';
// import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { collection, doc, getDocs, getDoc, query, where } from "firebase/firestore";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";
import axios from 'axios';

export const users: any = {}

users.signin = (data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const auth: any = getAuth();
    const { email, password } = data;

    signInWithEmailAndPassword(auth, email, password).then((UserCredential) => {
        const user: any = UserCredential.user;
        user.getIdTokenResult().then(async (idToeknResult: any) => {
            user.climes = idToeknResult.claims;
            const { isAs3aUser } = idToeknResult.claims;
            if (isAs3aUser === true) {
                dispatch({ type: GET_USER_LIST, payload: user });
                dispatch({ type: API_ERROR, payload: "" });
                dispatch({ type: API_SUCCESS, payload: {} })
            } else {
                signOut(auth).then(() => { });
                dispatch({ type: GET_USER_LIST, payload: [] });
                const error = "Invalid User"
                dispatch({ type: API_ERROR, payload: error });
            }
        })
    }).catch((error: any) => {
        let newerror = error.code.split('/')[1];
        dispatch({ type: API_ERROR, payload: newerror });
    })

}

users.signOut = () => async (dispatch: any) => {
    const auth = getAuth();
    signOut(auth).then(() => {
        dispatch({ type: GET_USER_LIST, payload: {} });
        dispatch({ type: API_ERROR, payload: "" });
        dispatch({ type: API_SUCCESS, payload: {} })
    }).catch((error) => {
        let newerror = error.code.split('/')[1];
        dispatch({ type: API_ERROR, payload: newerror });
    })
}


users.forgetPassword = (data: any) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, data.email).then(() => {
        dispatch({ type: RESET_PASSWORD, payload: true });
        dispatch({ type: API_ERROR, payload: "" });
    }).catch((error) => {
        let newerror = error.code.split('/')[1];
        dispatch({ type: API_ERROR, payload: newerror });
        dispatch({ type: RESET_PASSWORD, payload: false });
    })

}

users.refreshErr = () => async (dispatch: any) => {
    dispatch({ type: API_ERROR, payload: "" });
    dispatch({ type: RESET_PASSWORD, payload: false });
}




users.positionBranch = (ischeck: boolean) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: POSITION_BRANCH_CHECK, payload: ischeck })
}

users.branchPositionCreate = (isCheck: boolean) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    dispatch({ type: BRANCH_POSITION_CREATE_CHECK, payload: isCheck })
}


users.permissions = (id: string) => async (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    try {
        const conRef = doc(getFirebase(), "all_users", id);
        const docSnap: any = await getDoc(conRef);
        const data: any = docSnap.data();
        dispatch({ type: GET_USER_PERMISSIONS, payload: [data] })
    } catch (error: any) {
        dispatch({ type: GET_USER_PERMISSIONS, payload: [] })
    }


}




