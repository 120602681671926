import React, {FC, useEffect, useRef, useState, createRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useForm} from 'react-hook-form'
import {useSelector, useDispatch} from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {userList} from '../../../redux/actions/usersAction'
import {RootState} from '../../../redux/reducers'
import TextFieldGroup from '../../../comomn/TextFieldGroup'
import CheckBox from '../../../comomn/CheckBox'
import AddUserValidations from '../../../comomn/validations/users/addUser'

interface Porps {
  isActive: string
  userObj: any
  isPopup: boolean
  loading: boolean
  setLoading: (val: boolean) => void
  closeModalRef: any
  // isclearData: boolean
}

type FormValues = {
  name_en: string
  email: string
  permissions: {
    finance: {
      view: boolean
      manage_payments: boolean
      manage_invoices: boolean
      manage_bank_info: boolean
    }
    ambitious: {
      view: boolean
      edit_ambitious: boolean
    }
    dashboard: {
      view_growth: boolean
    }
    companies: {
      create_branch: boolean
      edit_company: boolean
      edit_branch: boolean
      create_position: boolean
      create_company: boolean
      view: boolean
      edit_position: boolean
    }
    users: {
      manage_users: boolean
      view: boolean
    }
  }
}

const UserModel: FC<Porps> = ({isActive, userObj, loading, isPopup, setLoading, closeModalRef}) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: {errors},
    reset,
  } = useForm<FormValues>()
  const auth: any = useSelector<RootState>((state) => state.auth)
  // const closeModalRef: any = createRef()

  const MySwal = withReactContent(Swal)
  const dispatch = useDispatch()
  useEffect(() => {
    const resetFn = () => {
      if (isActive === 'edit') {
        const values: any = Object.values(userObj)
        Object.keys(userObj).map((item: any, i) => setValue(item, values[i]))
        clearErrors(['name_en', 'email'])
      } else {
        reset()
        clearErrors(['name_en', 'email'])
      }
      if (isPopup) {
        cancelFn()
      }
    }
    resetFn()
    return () => {
      resetFn()
    }
  }, [isActive, userObj, isPopup])

  const submit = async (data: any) => {
    setLoading(true)
    dispatch(userList.addUser(data))
  }

  const updateUser = async (data: any) => {
    // console.log(closeModalRef)
    let id: string = data.id
    setLoading(true)
    dispatch(userList.userById(id, data))
  }
  const cancelFn = () => {
    if (isActive === 'edit') {
      const values: any = Object.values(userObj)
      Object.keys(userObj).map((item: any, i) => setValue(item, values[i]))
      clearErrors(['name_en', 'email'])
    } else {
      reset()
      clearErrors(['name_en', 'email'])
    }
  }

  return (
    <div className='modal fade' id='kt_modal_create_app1' aria-hidden='true' ref={closeModalRef}>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <form className='form'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='fw-bolder m-0'>{isActive === 'add' ? 'Add User' : 'Edit User'}</h3>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={cancelFn}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
              <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                <div className='row fv-row'>
                  <div className='col-sm-12'>
                    <div className='row fv-row mb-5'>
                      <div className='col-sm-6'>
                        <TextFieldGroup
                          label='Name'
                          type='text'
                          name='name_en'
                          register={register('name_en', AddUserValidations.name_en)}
                          isValidate='true'
                          error={errors.name_en && errors.name_en.message}
                          placeholder='Enter name in English'
                        />
                      </div>
                      {isActive === 'add' ? (
                        <div className='col-sm-6'>
                          <TextFieldGroup
                            label='Email'
                            type='text'
                            name='email'
                            register={register('email', AddUserValidations.email)}
                            isValidate='true'
                            error={errors.email && errors.email.message}
                            placeholder='Enter email'
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='col-sn-12 mb-5 border-gray-200 border-bottom-dashed'>
                    <h5 className='fw-bolder m-0 fs-6 mb-10'>Permissions</h5>
                    <h5 className='fw-bolder m-0 fs-6 mb-10'>Dashboard</h5>
                    <div className='col-sm mb-10'>
                      <CheckBox
                        label='View Growth'
                        type='checkbox'
                        name='view_growth'
                        register={register('permissions.dashboard.view_growth')}
                      />
                    </div>
                  </div>
                  <div
                    className='col-sm-12 mb-10 border-gray-200 border-bottom-dashed'
                    // style={{borderBottom: '1px dashed #eff2f5'}}
                  >
                    <div className='row fv-row'>
                      <div className='col-sm mb-10'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...register('permissions.companies.view')}
                          />
                          <label className='form-check-label fw-bolder'>Companies Access</label>
                        </div>
                      </div>
                    </div>
                    <div className='row fv-row mb-10'>
                      <div className='col-sm-3 mb-5'>
                        <CheckBox
                          label='Create company'
                          type='checkbox'
                          name='create_company'
                          register={register('permissions.companies.create_company')}
                        />
                      </div>
                      <div className='col-sm-3 mb-5'>
                        <CheckBox
                          label='Edit companies'
                          type='checkbox'
                          name='edit_company'
                          register={register('permissions.companies.edit_company')}
                        />
                      </div>
                      <div className='col-sm-3 mb-5'>
                        <CheckBox
                          label='Create branches'
                          type='checkbox'
                          name='create_branch'
                          register={register('permissions.companies.create_branch')}
                        />
                      </div>
                      <div className='col-sm-3 mb-5'>
                        <CheckBox
                          label='Edit branches'
                          type='checkbox'
                          name='edit_branch'
                          register={register('permissions.companies.edit_branch')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 mb-10 border-gray-200 border-bottom-dashed'>
                    <div className='row fv-row'>
                      <div className='col-sm-3 mb-10'>
                        <CheckBox
                          label='Create positions'
                          type='checkbox'
                          name='create_position'
                          register={register('permissions.companies.create_position')}
                          value=''
                        />
                      </div>
                      <div className='col-sm-3 mb-10'>
                        <CheckBox
                          label='Edit positions'
                          type='checkbox'
                          name='edit_position'
                          register={register('permissions.companies.edit_position')}
                          value=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 mb-5 border-gray-200 border-bottom-dashed'>
                    <div className='row fv-row'>
                      <div className='col-sm-12 mb-10'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...register('permissions.ambitious.view')}
                          />
                          <label className='form-check-label fw-bolder'>Ambitious Access</label>
                        </div>
                      </div>
                      <div className='col-sm-12 mb-10'>
                        <CheckBox
                          label='Edit ambitious'
                          type='checkbox'
                          name='edit_ambitious'
                          register={register('permissions.ambitious.edit_ambitious')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 mb-10 border-gray-200 border-bottom-dashed'>
                    <div className='row fv-row'>
                      <div className='col-sm-12 mb-10'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...register('permissions.finance.view')}
                          />
                          <label className='form-check-label fw-bolder'>Finance Access</label>
                        </div>
                      </div>
                      <div className='col-sm-4 sm-10'>
                        <CheckBox
                          label='Manage invoices'
                          type='checkbox'
                          name='manage_invoices'
                          register={register('permissions.finance.manage_invoices')}
                        />
                      </div>
                      <div className='col-sm-4 mb-5'>
                        <CheckBox
                          label='Manage payments'
                          type='checkbox'
                          name='managePayment'
                          register={register('permissions.finance.manage_payments')}
                        />
                      </div>
                      <div className='col-sm-4 sm-5'>
                        <CheckBox
                          label='Manage bank info'
                          type='checkbox'
                          name='manage_bank_info'
                          register={register('permissions.finance.manage_bank_info')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 mb-10 border-gray-200 border-bottom-dashed'>
                    <div className='row fv-row'>
                      <div className='col-sm-12 mb-10'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...register('permissions.users.view')}
                          />
                          <label className='form-check-label fw-bolder'>Users Access</label>
                        </div>
                      </div>
                      <div className='col-sm-12 mb-10'>
                        <CheckBox
                          label='Manage users'
                          type='checkbox'
                          name='manage_users'
                          register={register('permissions.users.manage_users')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='pt-5 text-center'>
                    <button
                      type='button'
                      className='btn btn-light me-5'
                      data-bs-dismiss='modal'
                      onClick={cancelFn}
                    >
                      Cancel
                    </button>
                    {isActive === 'add' ? (
                      <>
                        <button
                          type='button'
                          onClick={handleSubmit(submit)}
                          className='btn btn-bg-primary btn-color-white'
                          data-bs-dismiss={isPopup ? 'modal' : ''}
                        >
                          Submit
                          {loading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            ''
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className='btn btn-bg-primary btn-color-white'
                          onClick={handleSubmit(updateUser)}
                          data-bs-dismiss={isPopup === true ? 'modal' : ''}
                        >
                          Update
                          {loading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            ''
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export {UserModel}
